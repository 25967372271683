// components/FinalScores.js
import React, { useState, useEffect } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Image,
	TouchableOpacity,
	ScrollView,
	Animated,
} from 'react-native';
import LottieView from 'lottie-react-native';
import { Button } from 'react-native-paper';
import { avatars } from './avatars';

// Import the crown image
// import crownImage from '../assets/images/crown.png';

const FinalScores = ({ finalScores, onStartNewGame }) => {
	const [animationFinished, setAnimationFinished] = useState(false);
	const [showContinueButton] = useState(new Animated.Value(0));
	const [screenClicked, setScreenClicked] = useState(false);
	const [expandedPlayer, setExpandedPlayer] = useState(null);

	const toggleExpandPlayer = playerName => {
		setExpandedPlayer(prev => (prev === playerName ? null : playerName));
	};

	// Sort scores from best to worst
	const sortedScores = [...finalScores].sort(
		(a, b) => a.finalScore - b.finalScore
	);
	const bestScore = sortedScores[0].finalScore;
	const winners = sortedScores.filter(player => player.finalScore === bestScore);

	// Fade in the "Click to Continue" button after the animation ends
	const handleAnimationFinish = () => {
		setAnimationFinished(true);
		Animated.timing(showContinueButton, {
			toValue: 1,
			duration: 1000,
			useNativeDriver: true,
		}).start();
	};

	// Handle screen click to skip to final scores
	const handleScreenClick = () => {
		setScreenClicked(true);
	};

	return (
		<View style={styles.container}>
			{!screenClicked ? (
				<TouchableOpacity
					style={styles.animationContainer}
					onPress={handleScreenClick}
					activeOpacity={1}
				>
					{/* Confetti Animation */}
					<LottieView
						source={require('../assets/animations/confetti.json')}
						autoPlay
						loop={false}
						onAnimationFinish={handleAnimationFinish}
						style={styles.lottieAnimation}
					/>

					{/* Winners Display */}
					<View style={styles.winnersContainer}>
						{sortedScores.map(player => {
							const isWinner = player.finalScore === bestScore;
							return (
								<View key={player.name} style={styles.winnerItem}>
									<View style={styles.beanBagContainer}>
										{player.avatar || player.avatar === 0 ? (
											<Image
												source={avatars[player.avatar].image}
												style={[styles.avatarLarge, isWinner && styles.winnerAvatar]}
											/>
										) : (
											<View style={styles.avatarPlaceholderLarge}>
												<Text style={styles.avatarInitial}>{player.name.charAt(0)}</Text>
											</View>
										)}
										{/* {isWinner && <Image source={crownImage} style={styles.crownImage} />} */}
									</View>
									<Text style={[styles.winnerName, isWinner && styles.winnerNameGold]}>
										{player.name} - {player.finalScore}
									</Text>
								</View>
							);
						})}
					</View>

					{/* "Click to Continue" Button */}
					{animationFinished && (
						<Animated.View
							style={[styles.continueButtonContainer, { opacity: showContinueButton }]}
						>
							<Text style={styles.continueButtonText}>Click to Continue</Text>
						</Animated.View>
					)}
				</TouchableOpacity>
			) : (
				// Final Scores Display
				<View style={styles.finalScoresContainer}>
					<ScrollView
						style={styles.scoresContainer}
						contentContainerStyle={{ width: '100%', height: '100%', flex: 1 }}
					>
						<View style={styles.tableHeader}>
							<Text
								style={[styles.tableHeaderText, { flex: 2, alignSelf: 'flex-start' }]}
							>
								Player
							</Text>
							<Text style={[styles.tableHeaderText, { flex: 1 }]}>Strokes</Text>
							<Text style={[styles.tableHeaderText, { flex: 1 }]}>Board Credits</Text>
							<Text style={[styles.tableHeaderText, { flex: 1 }]}>Final Score</Text>
						</View>
						{sortedScores.map(player => {
							const isWinner = player.finalScore === bestScore;
							return (
								<View key={player.name}>
									<TouchableOpacity
										style={styles.playerRow}
										onPress={() => toggleExpandPlayer(player.name)}
									>
										{player.avatar || player.avatar === 0 ? (
											<Image
												source={avatars[player.avatar].image}
												style={[styles.avatarSmall, isWinner && styles.winnerAvatarSmall]}
											/>
										) : (
											<View style={styles.avatarPlaceholderSmall}>
												<Text style={styles.avatarInitial}>{player.name.charAt(0)}</Text>
											</View>
										)}
										{/* {isWinner && (
											// <Image source={crownImage} style={styles.crownImageSmall} />
										)} */}
										<Text
											style={[
												styles.playerName,
												{ flex: 2 },
												isWinner && styles.winnerNameGold,
											]}
										>
											{player.name}
										</Text>
										<Text style={[styles.scoreText, { flex: 1 }]}>
											{player.totalShots}
										</Text>
										<Text style={[styles.scoreText, { flex: 1 }]}>
											{player.totalBoardCredits}
										</Text>
										<Text style={[styles.scoreText, { flex: 1 }]}>
											{player.finalScore}
										</Text>
									</TouchableOpacity>
									{expandedPlayer === player.name && (
										<View style={styles.holeDetails}>
											<View style={styles.holeDetailsHeader}>
												<Text style={[styles.holeDetailText, { flex: 1 }]}>Hole</Text>
												<Text style={[styles.holeDetailText, { flex: 1 }]}>Strokes</Text>
												<Text style={[styles.holeDetailText, { flex: 1 }]}>
													Board Credits
												</Text>
											</View>
											{player.holeDetails.map((hole, idx) => (
												<View key={`${hole.hole}-${idx}`} style={styles.holeDetailRow}>
													<Text style={[styles.holeDetailText, { flex: 1 }]}>
														{hole.hole}
													</Text>
													<Text style={[styles.holeDetailText, { flex: 1 }]}>
														{hole.totalScore || 4}
													</Text>
													<Text style={[styles.holeDetailText, { flex: 1 }]}>
														{hole.boardCredits}
													</Text>
												</View>
											))}
										</View>
									)}
								</View>
							);
						})}
					</ScrollView>
					<View style={styles.buttonsContainer}>
						<Button mode="contained" onPress={onStartNewGame} style={styles.button}>
							<Text style={styles.buttonText}>Home</Text>
						</Button>
					</View>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		height: '95vh',
		height: '100dvh',
		width: '100%',
		overflow: 'hidden',
		backgroundColor: '#282c34',
	},
	animationContainer: {
		// flex: 1,
		position: 'relative',
		height: '95vh',
		height: '100dvh',
		overflow: 'hidden',
		width: '100%',
	},
	lottieAnimation: {
		// flex: 1,
		height: '95vh',
		height: '100dvh',
		width: '100%',
	},
	winnersContainer: {
		position: 'absolute',
		top: '5%',
		left: 0,
		right: 0,
		alignItems: 'center',
		paddingHorizontal: 20,
	},
	winnerItem: {
		flexDirection: 'row',
		alignItems: 'center',
		marginVertical: 10,
	},
	beanBagContainer: {
		position: 'relative',
		marginRight: 15,
	},
	avatarLarge: {
		width: 60,
		height: 60,
		// borderRadius: 40,
	},
	winnerAvatar: {
		// borderWidth: 3,
		// borderColor: 'gold',
	},
	crownImage: {
		position: 'absolute',
		top: -20,
		left: 20,
		width: 40,
		height: 40,
		resizeMode: 'contain',
	},
	avatarPlaceholderLarge: {
		width: 60,
		height: 60,
		// borderRadius: 40,
		backgroundColor: '#555',
		justifyContent: 'center',
		alignItems: 'center',
	},
	avatarInitial: {
		fontSize: 32,
		color: '#fff',
	},
	winnerName: {
		fontSize: 24,
		color: '#fff',
		fontWeight: 'bold',
	},
	winnerNameGold: {
		color: 'gold',
		fontWeight: '900',
	},
	continueButtonContainer: {
		position: 'absolute',
		bottom: 50,
		left: 0,
		right: 0,
		alignItems: 'center',
		opacity: 0,
	},
	continueButtonText: {
		fontSize: 20,
		color: '#fff',
		fontWeight: 'bold',
		backgroundColor: 'rgba(0,0,0,0.5)',
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 20,
	},
	finalScoresContainer: {
		flex: 1,
		width: '100%',
		height: '100%',
	},
	scoresContainer: {
		flex: 1,
		width: '100%',
		height: '100%',
		paddingHorizontal: 20,
		marginTop: 20,
	},
	tableHeader: {
		flexDirection: 'row',
		borderBottomWidth: 2,
		marginTop: 10,
		borderBottomColor: '#fff',
		paddingBottom: 10,
		marginBottom: 10,
	},
	tableHeaderText: {
		color: '#fff',
		fontSize: 18,
		fontWeight: 'bold',
		textAlign: 'center',
	},
	playerRow: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#444',
		padding: 10,
		marginBottom: 5,
		borderRadius: 10,
	},
	avatarSmall: {
		width: 40,
		height: 40,
		borderRadius: 20,
	},
	winnerAvatarSmall: {
		borderWidth: 2,
		borderColor: 'gold',
	},
	crownImageSmall: {
		position: 'absolute',
		top: -10,
		left: 15,
		width: 20,
		height: 20,
		resizeMode: 'contain',
	},
	avatarPlaceholderSmall: {
		width: 40,
		height: 40,
		borderRadius: 20,
		backgroundColor: '#555',
		justifyContent: 'center',
		alignItems: 'center',
	},
	playerName: {
		color: '#fff',
		fontSize: 16,
		marginLeft: 10,
		fontWeight: 'bold',
	},
	scoreText: {
		color: '#fff',
		fontSize: 18,
		textAlign: 'center',
	},
	holeDetails: {
		backgroundColor: '#333',
		padding: 10,
		marginTop: 5,
		borderRadius: 5,
	},
	holeDetailsHeader: {
		flexDirection: 'row',
		borderBottomWidth: 1,
		borderBottomColor: '#fff',
		paddingBottom: 5,
		marginBottom: 5,
	},
	holeDetailRow: {
		flexDirection: 'row',
		paddingVertical: 2,
	},
	holeDetailText: {
		color: '#fff',
		fontSize: 16,
		textAlign: 'center',
	},
	buttonsContainer: {
		flexDirection: 'row',
		marginTop: 10,
		marginBottom: 40,
		paddingHorizontal: 20,
		justifyContent: 'space-between',
	},
	button: {
		flex: 1,
		marginHorizontal: 5,
		backgroundColor: '#ff7e5f',
		borderRadius: 10,
	},
	buttonText: {
		color: '#fff',
		fontSize: 18,
	},
});

export default FinalScores;
