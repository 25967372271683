// TutorialModal.js

import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import {
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	Modal,
	ScrollView,
} from 'react-native';

export default function TutorialModal({ visible, onClose, videoSource }) {
	return (
		<Modal
			visible={visible}
			animationType="slide"
			transparent={true}
			onRequestClose={onClose} // For Android back button
		>
			<View style={styles.modalBackground}>
				<View style={styles.modalContainer}>
					<TouchableOpacity style={styles.closeButtonX} onPress={onClose}>
						<Ionicons name="close" size={24} color="gray" />
					</TouchableOpacity>

					<Text style={styles.modalTitle}>App Tutorial</Text>
					<video
						style={styles.videoPlayer}
						controls
						src={videoSource}
						onClick={e => e.stopPropagation()} // Prevent closing modal when clicking on video
					/>
				</View>
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	modalBackground: {
		flex: 1,
		backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalContainer: {
		position: 'relative',
		width: '90%',
		maxHeight: '80%',
		backgroundColor: '#fff',
		borderRadius: 10,
		padding: 20,
	},
	closeButtonX: {
		position: 'absolute',
		top: 15,
		right: 15,
		zIndex: 1,
		padding: 10,
	},
	modalTitle: {
		fontSize: 24,
		marginBottom: 30,
		textAlign: 'center',
	},
	videoPlayer: {
		width: '100%',
		height: 'calc(100% - 60px)', // Subtract title height
		paddingBottom: '56.25%', // 16:9 Aspect Ratio
		marginBottom: 20,
	},
});
