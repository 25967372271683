// components/ArrowButton.js
import React from 'react';
import {
	TouchableOpacity,
	Text,
	StyleSheet,
	ImageBackground,
} from 'react-native';

const ArrowButton = ({ direction, text, onPress, disabled, textStyle }) => {
	// Determine which arrow image to use based on the direction prop
	const arrowImage =
		direction === 'back'
			? require('../assets/images/arrow_back2.png') // Ensure this image exists
			: require('../assets/images/arrow_forward2.png'); // Ensure this image exists

	return (
		<TouchableOpacity
			onPress={onPress}
			disabled={disabled}
			style={styles.touchable}
			activeOpacity={0.7} // Adjusts the opacity when pressed
		>
			<ImageBackground
				source={arrowImage}
				style={styles.imageBackground}
				resizeMode="contain"
			>
				<Text
					style={[styles.buttonText, disabled && styles.disabledText, textStyle]}
				>
					{text}
				</Text>
			</ImageBackground>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	touchable: {
		justifyContent: 'center',
		alignItems: 'center',
		width: '30%',
	},
	imageBackground: {
		width: 100, // Adjust based on your arrow image dimensions
		height: 100, // Adjust based on your arrow image dimensions
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonText: {
		position: 'absolute', // Position the text absolutely within the ImageBackground
		fontSize: 11, // Adjust font size as needed
		fontFamily: 'Nunito_900Black',
		color: 'black', // Choose a color that contrasts well with the arrow image
		textAlign: 'center',
		// Optional: Add text shadow for better visibility
	},
	disabledText: {
		color: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
	},
});

export default ArrowButton;
