import React, { useContext } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { GameContext } from '../contexts/GameContext';
import { avatars } from './avatars'; // Update the path accordingly

const PlayersDisplay = ({ onEditPlayer, playerNameColor = 'white' }) => {
	const { players } = useContext(GameContext);
	return (
		<View style={styles.container}>
			{players.map((player, index) => (
				<TouchableOpacity
					key={index}
					style={styles.playerContainer}
					onPress={() => onEditPlayer(index)}
				>
					{player.avatar || player.avatar === 0 ? (
						<Image source={avatars[player.avatar].image} style={styles.avatar} />
					) : (
						<View style={styles.placeholderAvatar}>
							<Text style={styles.initials}>{player.name[0]}</Text>
						</View>
					)}
					<Text style={[styles.playerName, { color: playerNameColor }]}>
						{player.name}
					</Text>
				</TouchableOpacity>
			))}
		</View>
	);
};

// Stylesheet for the component
const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
		width: '70%',
		marginVertical: 20,
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		padding: 10,
	},
	playerContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		margin: 10,
		width: 100,
	},
	avatar: {
		width: 50,
		height: 50,
	},
	placeholderAvatar: {
		width: 70,
		height: 70,
		borderRadius: 35,
		backgroundColor: '#555',
		justifyContent: 'center',
		alignItems: 'center',
	},
	initials: {
		fontSize: 28,
		color: '#fff',
	},
	playerName: {
		marginLeft: 20,
		fontSize: 30,
	},
});

export default PlayersDisplay;
