import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

const Button = ({
	onPress,
	onPressIn,
	onPressout,
	onMouseEnter,
	onMouseLeave,
	title,
	style,
	textStyle,
}) => {
	return (
		<TouchableOpacity
			onPress={onPress}
			onPressIn={onPressIn}
			onPressOut={onPressout}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={[styles.button, style]}
		>
			<Text style={[styles.buttonText, textStyle]}>{title}</Text>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	button: {
		width: '60%',
		height: 40,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0,0,0,0.2)', // Semi-transparent overlay
		transform: [{ scale: 1.1 }],
		borderRadius: 5,
		shadowColor: 'white',
		shadowOffset: { width: 0, height: 0 },
		shadowOpacity: 0.8,
		shadowRadius: 10,
		marginBottom: 20,
	},
	buttonText: {
		fontSize: 24,
		color: '#fff',
		fontWeight: 'bold',
		textShadowColor: 'rgba(0, 0, 0, 0.75)',
		textShadowOffset: { width: -1, height: 1 },
		textShadowRadius: 10,
		color: 'white',
		fontWeight: 'bold',
	},
});

export default Button;
