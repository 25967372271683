// App.js
import React, { useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Switch,
} from 'react-router-dom';
import GameModeSelection from './components/GameModeSelection';
import PlayerSetup from './components/PlayerSetup';
import Scorecard from './components/Scorecard';
import { GameProvider } from './contexts/GameContext';
import SplashScreen from './components/SplashScreen';

function App() {
	const [isLoading, setIsLoading] = useState(true);

	const handleSplashFinish = () => {
		setIsLoading(false);
	};

	return (
		<GameProvider>
			<Router>
				{isLoading ? (
					<SplashScreen onFinish={handleSplashFinish} />
				) : (
					<Routes>
						<Route exact path="/" element={<GameModeSelection />} />
						<Route path="/players" element={<PlayerSetup />} />
						<Route path="/scorecard" element={<Scorecard />} />
					</Routes>
				)}
			</Router>
		</GameProvider>
	);
}

export default App;
