// // components/EditPlayerModal.js
// import React, { useState, useRef, useEffect } from 'react';
// import {
// 	View,
// 	Text,
// 	TouchableOpacity,
// 	StyleSheet,
// 	Modal,
// 	ScrollView,
// 	TextInput,
// 	useWindowDimensions,
// 	Image,
// } from 'react-native';
// import Button from './Button';
// import AvatarSelector from './AvatarSelector';
// import { Ionicons, EvilIcons } from '@expo/vector-icons';
// import { avatars } from './avatars';

// const EditPlayerModal = ({
// 	modalVisible,
// 	handleCancel,
// 	handleSavePlayer,
// 	handleDeletePlayer,
// 	editingPlayerIndex,
// 	playerName,
// 	setPlayerName,
// 	selectedAvatar,
// 	handleAvatarSelect,
// 	getDisabledAvatars,
// }) => {
// 	const { height } = useWindowDimensions();

// 	// State variables to track heights
// 	const [contentHeight, setContentHeight] = useState(0);
// 	const [containerHeight, setContainerHeight] = useState(0);
// 	const [isScrollable, setIsScrollable] = useState(false);
// 	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
// 		useState(false); // State for confirmation modal

// 	const scrollViewRef = useRef(null);

// 	// Effect to determine if ScrollView is scrollable
// 	useEffect(() => {
// 		if (contentHeight > containerHeight) {
// 			setIsScrollable(true);
// 		} else {
// 			setIsScrollable(false);
// 		}
// 	}, [contentHeight, containerHeight]);

// 	// Functions to handle deletion
// 	const handleInitiateDeletePlayer = () => {
// 		handleDeletePlayer();
// 	};

// 	return (
// 		<Modal
// 			animationType="slide"
// 			transparent={true}
// 			visible={modalVisible}
// 			onRequestClose={handleCancel}
// 		>
// 			<View style={styles.editModalOverlay}>
// 				<View
// 					style={[styles.editModalContainer, { maxHeight: height * 0.8 }]}
// 					onLayout={event => {
// 						const { height: layoutHeight } = event.nativeEvent.layout;
// 						setContainerHeight(layoutHeight);
// 					}}
// 				>
// 					<ScrollView
// 						ref={scrollViewRef}
// 						style={styles.scrollView}
// 						contentContainerStyle={{ paddingBottom: 40 }} // Extra padding for content peek
// 						onContentSizeChange={(width, height) => {
// 							setContentHeight(height);
// 						}}
// 					>
// 						{/* Modal Header */}
// 						<View style={styles.modalHeader}>
// 							<Text style={styles.editModalTitle}>
// 								{editingPlayerIndex !== null
// 									? `Player #${editingPlayerIndex + 1}`
// 									: `Add Player`}
// 							</Text>
// 							{editingPlayerIndex !== null && (
// 								<TouchableOpacity onPress={handleInitiateDeletePlayer}>
// 									<EvilIcons name="trash" size={28} color="#ff4d4d" />
// 								</TouchableOpacity>
// 							)}
// 						</View>

// 						{/* Name Input with Avatar */}
// 						<Text style={styles.modalLabel}>Name:</Text>
// 						<View style={styles.nameInputContainer}>
// 							{/* Conditionally render the avatar image */}
// 							{selectedAvatar !== null && (
// 								<Image
// 									source={avatars.find(avatar => avatar.id === selectedAvatar)?.image}
// 									style={styles.avatarImage}
// 								/>
// 							)}
// 							<TextInput
// 								style={[
// 									styles.modalInputWithAvatar,
// 									selectedAvatar !== null && styles.inputWithAvatarPadding,
// 								]}
// 								value={playerName}
// 								onChangeText={setPlayerName}
// 								placeholder="Enter player name"
// 								placeholderTextColor="#aaa"
// 							/>
// 						</View>

// 						{/* Avatars Selection */}
// 						<View style={styles.avatarSelectorContainer}>
// 							<Text style={styles.modalLabel}>Select Avatar:</Text>
// 							<AvatarSelector
// 								selectedAvatar={selectedAvatar}
// 								onSelectAvatar={handleAvatarSelect}
// 								disabledAvatars={getDisabledAvatars()}
// 							/>
// 						</View>

// 						{/* Invisible View to create a peek of more content */}
// 						<View style={{ height: 20 }} />
// 					</ScrollView>

// 					{/* Scroll Arrow Indicator */}
// 					{isScrollable && (
// 						<TouchableOpacity
// 							style={styles.scrollArrow}
// 							onPress={() => {
// 								scrollViewRef.current?.scrollToEnd({ animated: true });
// 							}}
// 						>
// 							<Ionicons name="arrow-down" size={24} color="#888" />
// 						</TouchableOpacity>
// 					)}

// 					{/* Modal Buttons */}
// 					<View style={styles.modalButtons}>
// 						<Button
// 							title="Cancel"
// 							onPress={handleCancel}
// 							style={styles.cancelButton}
// 						/>
// 						<Button
// 							title="Save"
// 							onPress={handleSavePlayer}
// 							style={styles.saveButton}
// 						/>
// 					</View>
// 				</View>
// 			</View>
// 		</Modal>
// 	);
// };

// const styles = StyleSheet.create({
// 	editModalOverlay: {
// 		flex: 1,
// 		backgroundColor: 'rgba(0,0,0,0.5)',
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 	},
// 	editModalContainer: {
// 		width: '90%',
// 		backgroundColor: '#fff',
// 		borderRadius: 20,
// 		padding: 20,
// 		shadowColor: '#000',
// 		shadowOffset: { width: 0, height: 2 },
// 		shadowOpacity: 0.25,
// 		shadowRadius: 4,
// 		elevation: 5,
// 	},
// 	modalHeader: {
// 		flexDirection: 'row',
// 		justifyContent: 'space-between',
// 		alignItems: 'center',
// 		marginBottom: 20,
// 	},
// 	editModalTitle: {
// 		fontSize: 20,
// 		fontWeight: 'bold',
// 		color: '#333',
// 		textAlign: 'center',
// 	},
// 	modalLabel: {
// 		fontSize: 16,
// 		marginBottom: 5,
// 		color: '#333',
// 	},
// 	nameInputContainer: {
// 		flexDirection: 'row',
// 		alignItems: 'center',
// 		marginBottom: 20,
// 		borderWidth: 1,
// 		borderColor: '#ccc',
// 		borderRadius: 10,
// 		paddingHorizontal: 10,
// 		paddingVertical: 5,
// 	},
// 	avatarImage: {
// 		width: 50,
// 		height: 50,
// 		marginRight: 10,
// 	},
// 	modalInputWithAvatar: {
// 		flex: 1,
// 		height: 50,
// 		paddingLeft: 10,
// 		fontSize: 16,
// 		color: '#333',
// 	},
// 	inputWithAvatarPadding: {
// 		paddingLeft: 0, // Adjust padding if avatar is present
// 	},
// 	avatarSelectorContainer: {
// 		marginBottom: 20,
// 	},
// 	modalButtons: {
// 		flexDirection: 'row',
// 		justifyContent: 'space-evenly',
// 		marginTop: 30,
// 		width: '100%',
// 	},
// 	cancelButton: {
// 		width: '40%',
// 		backgroundColor: '#F84413',
// 	},
// 	saveButton: {
// 		width: '40%',
// 		backgroundColor: '#4CAF50',
// 	},
// 	scrollView: {
// 		width: '100%',
// 		flex: 1,
// 	},
// 	scrollArrow: {
// 		position: 'absolute',
// 		bottom: 115,
// 		alignSelf: 'center',
// 		backgroundColor: 'rgba(255,255,255,0.7)',
// 		borderRadius: 20,
// 		padding: 5,
// 	},
// });

// export default EditPlayerModal;

// components/EditPlayerModal.js
import React, { useState, useRef, useEffect } from 'react';
import {
	View,
	Text,
	TouchableOpacity,
	StyleSheet,
	Modal,
	ScrollView,
	TextInput,
	useWindowDimensions,
	Image,
} from 'react-native';
import Button from './Button';
import AvatarSelector from './AvatarSelector';
import { Ionicons, EvilIcons } from '@expo/vector-icons';
import { avatars } from './avatars';

const EditPlayerModal = ({
	modalVisible,
	handleCancel,
	handleSavePlayer,
	handleDeletePlayer,
	editingPlayerIndex,
	playerName,
	setPlayerName,
	selectedAvatar,
	handleAvatarSelect,
	getDisabledAvatars,
}) => {
	const { height } = useWindowDimensions();

	// State variables to track heights
	const [contentHeight, setContentHeight] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);
	const [isScrollable, setIsScrollable] = useState(false);
	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false); // State for confirmation modal

	const scrollViewRef = useRef(null);

	// Effect to determine if ScrollView is scrollable
	useEffect(() => {
		if (contentHeight > containerHeight) {
			setIsScrollable(true);
		} else {
			setIsScrollable(false);
		}
	}, [contentHeight, containerHeight]);

	// Functions to handle deletion
	const handleInitiateDeletePlayer = () => {
		handleDeletePlayer();
	};

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={handleCancel}
		>
			<View style={styles.editModalOverlay}>
				<View
					style={[styles.editModalContainer, { maxHeight: height * 0.8 }]}
					onLayout={event => {
						const { height: layoutHeight } = event.nativeEvent.layout;
						setContainerHeight(layoutHeight);
					}}
				>
					<ScrollView
						ref={scrollViewRef}
						style={styles.scrollView}
						contentContainerStyle={{ paddingBottom: 40 }} // Extra padding for content peek
						onContentSizeChange={(width, height) => {
							setContentHeight(height);
						}}
					>
						{/* Modal Header */}
						<View style={styles.modalHeader}>
							<Text style={styles.editModalTitle}>
								{editingPlayerIndex !== null
									? `Player #${editingPlayerIndex + 1}`
									: `Add Player`}
							</Text>
							{editingPlayerIndex !== null && (
								<TouchableOpacity onPress={handleInitiateDeletePlayer}>
									<EvilIcons name="trash" size={28} color="#ff4d4d" />
								</TouchableOpacity>
							)}
						</View>

						{/* Name Input with Avatar */}
						<Text style={styles.modalLabel}>Name:</Text>
						<View style={styles.nameInputContainer}>
							{/* Conditionally render the avatar image */}
							{selectedAvatar !== null && (
								<Image
									source={avatars.find(avatar => avatar.id === selectedAvatar)?.image}
									style={styles.avatarImage}
								/>
							)}
							<TextInput
								style={[
									styles.modalInputWithAvatar,
									selectedAvatar !== null && styles.inputWithAvatarPadding,
								]}
								value={playerName}
								onChangeText={setPlayerName}
								placeholder="Enter player name"
								placeholderTextColor="#aaa"
								maxLength={10} // Restrict input to 10 characters
							/>
						</View>

						{/* Character Count */}
						<Text
							style={styles.charCount}
						>{`${playerName.length}/10 characters`}</Text>

						{/* Avatars Selection */}
						<View style={styles.avatarSelectorContainer}>
							<Text style={styles.modalLabel}>Select Avatar:</Text>
							<AvatarSelector
								selectedAvatar={selectedAvatar}
								onSelectAvatar={handleAvatarSelect}
								disabledAvatars={getDisabledAvatars()}
							/>
						</View>

						{/* Invisible View to create a peek of more content */}
						<View style={{ height: 20 }} />
					</ScrollView>

					{/* Scroll Arrow Indicator */}
					{isScrollable && (
						<TouchableOpacity
							style={styles.scrollArrow}
							onPress={() => {
								scrollViewRef.current?.scrollToEnd({ animated: true });
							}}
						>
							<Ionicons name="arrow-down" size={24} color="#888" />
						</TouchableOpacity>
					)}

					{/* Modal Buttons */}
					<View style={styles.modalButtons}>
						<Button
							title="Cancel"
							onPress={handleCancel}
							style={styles.cancelButton}
						/>
						<Button
							title="Save"
							onPress={handleSavePlayer}
							style={styles.saveButton}
						/>
					</View>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	editModalOverlay: {
		flex: 1,
		backgroundColor: 'rgba(0,0,0,0.5)',
		justifyContent: 'center',
		alignItems: 'center',
	},
	editModalContainer: {
		width: '90%',
		backgroundColor: '#fff',
		borderRadius: 20,
		padding: 20,
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	modalHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 20,
	},
	editModalTitle: {
		fontSize: 20,
		fontWeight: 'bold',
		color: '#333',
		textAlign: 'center',
	},
	modalLabel: {
		fontSize: 16,
		marginBottom: 5,
		color: '#333',
	},
	nameInputContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 5, // Reduced to make space for character count
		borderWidth: 1,
		borderColor: '#ccc',
		borderRadius: 10,
		paddingHorizontal: 10,
		paddingVertical: 5,
	},
	avatarImage: {
		width: 50,
		height: 50,
		marginRight: 10,
	},
	modalInputWithAvatar: {
		flex: 1,
		height: 50,
		paddingLeft: 10,
		fontSize: 16,
		color: '#333',
	},
	inputWithAvatarPadding: {
		paddingLeft: 0, // Adjust padding if avatar is present
	},
	charCount: {
		alignSelf: 'flex-start',
		marginBottom: 15,
		fontSize: 14,
		color: '#666',
	},
	avatarSelectorContainer: {
		marginBottom: 20,
	},
	modalButtons: {
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		marginTop: 30,
		width: '100%',
	},
	cancelButton: {
		width: '40%',
		backgroundColor: '#F84413',
	},
	saveButton: {
		width: '40%',
		backgroundColor: '#4CAF50',
	},
	scrollView: {
		width: '100%',
		flex: 1,
	},
	scrollArrow: {
		position: 'absolute',
		bottom: 115,
		alignSelf: 'center',
		backgroundColor: 'rgba(255,255,255,0.7)',
		borderRadius: 20,
		padding: 5,
	},
});

export default EditPlayerModal;
