// components/Scorecard.js
import React, { useContext, useState, useEffect } from 'react';
import {
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	Image,
	Dimensions,
	ImageBackground,
	Animated,
	ActivityIndicator,
} from 'react-native';
import { GameContext } from '../contexts/GameContext';
import FinalScores from './FinalScores';
import { useNavigate } from 'react-router-dom';
import {
	useFonts,
	CoveredByYourGrace_400Regular,
} from '@expo-google-fonts/covered-by-your-grace';
import { Nunito_900Black } from '@expo-google-fonts/nunito';
import ArrowButton from './ArrowButton';
import MenuModal from './MenuModal';
import { avatars } from './avatars'; // Ensure avatars are centralized

const { width } = Dimensions.get('window');
const shotTypes = ['drive', 'chip', 'putt'];

const Scorecard = () => {
	const {
		players,
		setScores,
		scores,
		currentHole,
		setCurrentHole,
		shotType,
		setShotType,
		isLoaded,
		setGameFinished,
		gameFinished,
	} = useContext(GameContext);
	// const [gameFinished, setGameFinished] = useState(false);
	const [finalScores, setFinalScores] = useState([]);
	const navigate = useNavigate();
	const [containerHeight, setContainerHeight] = useState(0);
	const [fontSize, setFontSize] = useState(24);
	const [holeTextFontSize, setHoleTextFontSize] = useState(30);
	const [menuVisible, setMenuVisible] = useState(false);
	const [standingsVisible, setStandingsVisible] = useState(false);

	// Animated values for fading
	const [originalOpacity] = useState(new Animated.Value(1));
	const [standingsOpacity] = useState(new Animated.Value(0));

	// State to track each player's selection for the current shot
	const [playerSelections, setPlayerSelections] = useState({});

	// Dynamically adjust font size based on container size
	const handleLayout = event => {
		const { height } = event.nativeEvent.layout;
		setContainerHeight(height);

		// Adjust font size based on the container's height (customize this ratio)
		const newFontSize = height / 5.5; // Modify this ratio to fit your needs
		setFontSize(newFontSize);

		// Adjust font size for hole text
		const newHoleTextFontSize = height / 2.6;
		setHoleTextFontSize(newHoleTextFontSize);
	};

	const [fontsLoaded] = useFonts({
		CoveredByYourGrace_400Regular,
		Nunito_900Black,
	});

	// Load existing player selections when currentHole or shotType changes
	useEffect(() => {
		const currentHoleData = scores[currentHole] || {};
		const newPlayerSelections = {};

		players.forEach((player, index) => {
			const playerHoleData = currentHoleData[index] || { shots: [] };
			const shotData = playerHoleData.shots.find(
				shot => shot.shotType === shotType
			);
			if (shotData) {
				newPlayerSelections[index] = shotData.result;
			}
		});

		setPlayerSelections(newPlayerSelections);
	}, [currentHole, shotType, scores, players]);

	// Update animation when standingsVisible changes
	useEffect(() => {
		if (standingsVisible) {
			// Fade out original content, fade in standings
			Animated.parallel([
				Animated.timing(originalOpacity, {
					toValue: 0,
					duration: 300,
					useNativeDriver: true,
				}),
				Animated.timing(standingsOpacity, {
					toValue: 1,
					duration: 300,
					useNativeDriver: true,
				}),
			]).start();
		} else {
			// Fade in original content, fade out standings
			Animated.parallel([
				Animated.timing(originalOpacity, {
					toValue: 1,
					duration: 300,
					useNativeDriver: true,
				}),
				Animated.timing(standingsOpacity, {
					toValue: 0,
					duration: 300,
					useNativeDriver: true,
				}),
			]).start();
		}
	}, [standingsVisible]);

	useEffect(() => {
		if (gameFinished) {
			calculateFinalScores();
		}
	}, []);

	// Function to detect OS (used for touch events)
	function getOS() {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone';
		}

		if (/android/i.test(userAgent)) {
			return 'Android';
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS';
		}

		if (/Macintosh|Mac OS X/i.test(userAgent)) {
			return 'MacOS';
		}

		if (/Windows NT|Win32|Win64|WOW64/i.test(userAgent)) {
			return 'Windows';
		}

		if (/Linux/i.test(userAgent)) {
			return 'Linux';
		}

		return 'unknown';
	}

	// Check if all players have made selections for the current shot
	const allPlayersSelected = () => {
		return players.every((_, index) => {
			const playerHoleScores = scores[currentHole]?.[index] || {
				madeItIn: false,
			};
			const hasMadeItIn = playerHoleScores.madeItIn;
			const madeItInShotIndex = shotTypes.indexOf(
				playerHoleScores.madeItInShotType
			);
			const currentShotIndex = shotTypes.indexOf(shotType);

			if (hasMadeItIn && currentShotIndex > madeItInShotIndex) {
				// Player has completed this and subsequent shots
				return true;
			}

			// Require selection for current shot
			return playerSelections.hasOwnProperty(index);
		});
	};

	// Handle player selection
	const handleSelection = (playerIndex, result) => {
		const playerHoleScore = scores[currentHole]?.[playerIndex] || {
			madeItIn: false,
		};
		if (playerHoleScore.madeItIn) {
			const madeItInShotIndex = shotTypes.indexOf(
				playerHoleScore.madeItInShotType
			);
			const currentShotIndex = shotTypes.indexOf(shotType);
			if (currentShotIndex > madeItInShotIndex) {
				// Do not allow selection changes after the earliest shot where the player made it in
				return;
			}
		}
		// Proceed to handle selection
		setPlayerSelections(prevSelections => {
			const currentSelection = prevSelections[playerIndex];
			if (currentSelection === result) {
				// Deselect if the same button is pressed again
				const updatedSelections = { ...prevSelections };
				delete updatedSelections[playerIndex];
				return updatedSelections;
			} else {
				// Update selection
				return {
					...prevSelections,
					[playerIndex]: result,
				};
			}
		});
	};

	// Next button handler
	const nextStep = () => {
		// Update scores with playerSelections
		const updatedScores = { ...scores };
		if (!updatedScores[currentHole]) {
			updatedScores[currentHole] = {};
		}

		players.forEach((player, index) => {
			const playerIndex = index;
			const result = playerSelections[playerIndex];

			if (!updatedScores[currentHole][playerIndex]) {
				updatedScores[currentHole][playerIndex] = {
					shots: [],
					boardCredits: 0,
					totalScore: 0,
					madeItIn: false,
					madeItInShotType: null,
				};
			}
			const playerHoleScores = updatedScores[currentHole][playerIndex];

			// Remove previous shot of the same type if it exists
			const previousShot = playerHoleScores.shots.find(
				shot => shot.shotType === shotType
			);

			if (previousShot) {
				// Adjust boardCredits if previous result was 'on'
				if (previousShot.result === 'on') {
					playerHoleScores.boardCredits -= 1;
				}
				// Remove the previous shot
				playerHoleScores.shots = playerHoleScores.shots.filter(
					shot => shot.shotType !== shotType
				);
			}

			// Update boardCredits based on new result
			if (result === 'on') {
				playerHoleScores.boardCredits += 1;
			}

			// Add the new shot
			const shotValue = result === 'in' ? shotTypes.indexOf(shotType) + 1 : 0;
			playerHoleScores.shots.push({ shotType, result, shotValue });

			// Recalculate madeItIn, madeItInShotType, and totalScore
			const inShots = playerHoleScores.shots.filter(shot => shot.result === 'in');
			if (inShots.length > 0) {
				// Find the earliest shot where the player made it in
				const earliestInShot = inShots.reduce((earliest, current) => {
					return shotTypes.indexOf(current.shotType) <
						shotTypes.indexOf(earliest.shotType)
						? current
						: earliest;
				});
				playerHoleScores.madeItIn = true;
				playerHoleScores.madeItInShotType = earliestInShot.shotType;
				playerHoleScores.totalScore =
					shotTypes.indexOf(earliestInShot.shotType) + 1;
			} else if (playerHoleScores.shots.length === 3) {
				// Player did not make it IN after all shots
				playerHoleScores.madeItIn = true; // Mark as completed
				playerHoleScores.madeItInShotType = 'putt';
				playerHoleScores.totalScore = 4;
			} else {
				// Player hasn't made it in yet
				playerHoleScores.madeItIn = false;
				playerHoleScores.madeItInShotType = null;
				playerHoleScores.totalScore = 0; // Or handle as needed
			}

			// Update the hole scores
			updatedScores[currentHole][playerIndex] = playerHoleScores;
		});

		// Update the scores in context
		setScores(updatedScores);

		// Reset playerSelections
		setPlayerSelections({});

		if (shotType === 'putt') {
			if (currentHole < 18) {
				setCurrentHole(currentHole + 1);
				setShotType('drive'); // Move to 'drive' for the next hole
			} else {
				// Game finished
				calculateFinalScores();
				setGameFinished(true);
			}
		} else {
			const nextIndex = shotTypes.indexOf(shotType) + 1;
			setShotType(shotTypes[nextIndex]); // Move to the next shot type
		}
	};

	// Back button handler
	const previousStep = () => {
		if (shotType === 'drive') {
			if (currentHole > 1) {
				setCurrentHole(currentHole - 1);
				setShotType('putt'); // Move to 'putt' when going back a hole
			}
		} else {
			const prevIndex = shotTypes.indexOf(shotType) - 1;
			setShotType(shotTypes[prevIndex]); // Move to the previous shot type
		}
	};

	// Calculate final scores
	const calculateFinalScores = () => {
		const finalScores = players.map((player, index) => {
			let totalShots = 0;
			let totalBoardCredits = 0;
			const holeDetails = [];

			for (let hole = 1; hole <= 18; hole++) {
				const playerHoleScores = scores[hole]?.[index];
				let holeScore = 0;
				let holeCredits = 0;
				if (playerHoleScores) {
					holeScore = playerHoleScores.totalScore || 4;
					holeCredits = playerHoleScores.boardCredits || 0;
					totalShots += holeScore;
					totalBoardCredits += holeCredits;
				} else {
					// If no data for the hole, assume maximum score
					holeScore = 4;
					holeCredits = 0;
					totalShots += holeScore;
				}
				holeDetails.push({
					hole,
					totalScore: holeScore,
					boardCredits: holeCredits,
				});
			}

			const deductions = Math.floor(totalBoardCredits / 3);
			const finalScore = totalShots - deductions;

			return {
				name: player.name,
				avatar: player.avatar,
				totalShots,
				totalBoardCredits,
				finalScore,
				holeDetails, // Include hole details
			};
		});
		setFinalScores(finalScores);
	};

	// Compute player scores for standings
	const computePlayerScores = () => {
		const playerScores = players.map((player, index) => {
			let totalShots = 0;
			let totalBoardCredits = 0;

			for (let hole = 1; hole <= currentHole; hole++) {
				const playerHoleScores = scores[hole]?.[index];
				if (playerHoleScores) {
					totalShots += playerHoleScores.totalScore || 0;
					totalBoardCredits += playerHoleScores.boardCredits || 0;
				} else {
					// If no data for the hole, assume maximum score
					totalShots += 0;
				}
			}

			const deductions = Math.floor(totalBoardCredits / 3);
			const finalScore = totalShots - deductions;

			return {
				name: player.name,
				avatar: player.avatar,
				totalShots,
				totalBoardCredits,
				finalScore,
			};
		});

		return playerScores;
	};

	// Start a new game
	const startNewGame = () => {
		// Reset all states and navigate back to home
		setCurrentHole(1);
		setShotType('drive');
		setGameFinished(false);
		setFinalScores([]);
		setScores({});
		navigate('/');
	};

	// Compute sortedPlayers based on the criteria
	const sortedPlayers = players.map((player, index) => ({
		player,
		index, // Include original index
	}));

	// Sort the players
	sortedPlayers.sort((a, b) => comparePlayers(a, b, currentHole - 1));

	// Compare players function
	function comparePlayers(a, b, holeNumber) {
		if (holeNumber < 1) {
			// Use initial order
			return a.index - b.index;
		}

		const playerAScores = scores[holeNumber]?.[a.index];
		const playerBScores = scores[holeNumber]?.[b.index];

		let aScore = 0;
		let bScore = 0;
		let aCredits = 0;
		let bCredits = 0;

		if (playerAScores) {
			aScore = playerAScores.totalScore || 4;
			aCredits = playerAScores.boardCredits || 0;
		} else {
			aScore = 4; // Assume max score
		}

		if (playerBScores) {
			bScore = playerBScores.totalScore || 4;
			bCredits = playerBScores.boardCredits || 0;
		} else {
			bScore = 4; // Assume max score
		}

		if (aScore !== bScore) {
			return aScore - bScore; // Ascending order of prevHoleScore
		} else if (aCredits !== bCredits) {
			return bCredits - aCredits; // Descending order of prevHoleCredits
		} else {
			// Check previous hole order recursively
			return comparePlayers(a, b, holeNumber - 1);
		}
	}

	if (!fontsLoaded || !isLoaded) {
		return (
			<View style={styles.loadingContainer}>
				<ActivityIndicator size="large" color="#ffffff" />
			</View>
		);
	}

	const playerScores = computePlayerScores();

	return (
		<ImageBackground
			style={styles.container}
			source={require('../assets/images/textured_background2.png')}
			resizeMode="cover"
		>
			<MenuModal
				visible={menuVisible}
				onClose={() => {
					setMenuVisible(false);
				}}
				onResetGame={() => {
					setCurrentHole(1);
					setShotType('drive');
					setGameFinished(false);
					setFinalScores([]);
					setScores({});
					setMenuVisible(false);
					navigate('/players');
				}}
				onUpdatePlayerInfo={() => {
					setMenuVisible(false);
					navigate('/update-player-info');
				}}
				onShare={() => {
					setMenuVisible(false);
					navigate('/share');
				}}
				onShowRules={() => {
					var win = window.open(
						'https://www.playcornholegolf.com/rates-rules',
						'_blank'
					);
					win.focus();
				}}
			/>
			{!gameFinished ? (
				<>
					{/* Header */}
					<View style={styles.header}>
						<TouchableOpacity
							style={styles.menuButton}
							onPress={() => {
								setMenuVisible(true);
							}}
						>
							<Text style={styles.menuButtonText}>MENU</Text>
						</TouchableOpacity>
						{currentHole && (
							<Image
								source={require(`../assets/hole_numbers/${currentHole}.PNG`)}
								style={styles.logo}
							/>
						)}
					</View>

					<View
						style={{
							justifyContent: 'flex-start',
							alignItems: 'center',
							height: '90%',
							paddingBottom: 40,
						}}
					>
						{/* Image Background */}
						<ImageBackground
							style={{
								height: '100%',
								aspectRatio: 1718 / 3381,
								justifyContent: 'center',
								alignItems: 'center',
							}}
							source={
								standingsVisible
									? require('../assets/images/current_standings_background2.png')
									: shotType === 'drive'
									? require('../assets/images/drive_board2.PNG')
									: shotType === 'chip'
									? require('../assets/images/chip_board2.PNG')
									: require('../assets/images/putt_board2.PNG')
							}
							resizeMode="contain"
						>
							<View style={{ flex: 1, width: '100%', height: '100%' }}>
								<Animated.View
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										opacity: originalOpacity,
										justifyContent: 'flex-start',
										alignItems: 'center',
										userSelect: 'none',
									}}
								>
									<View
										style={{
											position: 'absolute',
											top: '20.5%',
											left: '7.85%',
											width: '84.4%',
											height: '75.5%',
											justifyContent: 'flex-start',
											alignItems: 'center',
										}}
									>
										{/* Player details */}
										<View
											style={{
												width: '100%',
												height: '16%',
												marginTop: '2%',
												flexDirection: 'row',
												borderBottomWidth: 1,
											}}
										>
											<View
												style={{
													flex: 2.2,
													borderRightWidth: 1,
													justifyContent: 'center',
												}}
												onLayout={handleLayout}
											>
												<Text style={[styles.dynamicText, { fontSize: holeTextFontSize }]}>
													Hole {currentHole}
												</Text>
											</View>

											{['Made  it in', 'Board  Credit', 'Missed  the  Board'].map(
												(label, idx) => (
													<View
														key={idx}
														style={[
															styles.columnContainer,
															{
																flex: 1,
																borderRightWidth: idx < 2 ? 1 : 0,
															},
														]}
													>
														{label.split('  ').map((word, i) => (
															<Text key={i} style={[styles.dynamicTextComic, { fontSize }]}>
																{word}
															</Text>
														))}
													</View>
												)
											)}
										</View>
										<View
											style={{
												height: '2%',
												width: '100%',
												flexDirection: 'row',
												justifyContent: 'flex-start',
											}}
										>
											<View
												style={{
													flex: 2.2,
													borderRightWidth: 1,
													height: '100%',
												}}
											/>
											<View
												style={{
													flex: 1,
													borderRightWidth: 1,
													height: '100%',
												}}
											/>
											<View
												style={{
													flex: 1,
													borderRightWidth: 1,
													height: '100%',
												}}
											/>
											<View
												style={{
													flex: 1,
													height: '100%',
												}}
											/>
										</View>
										<View
											style={{
												width: '100%',
												height: '60%',
												flexDirection: 'column',
												justifyContent: 'space-evenly',
												borderBottomWidth: 1,
											}}
										>
											{sortedPlayers.map(playerObj => {
												const player = playerObj.player;
												const playerIndex = playerObj.index;

												const playerHoleScores = scores[currentHole]?.[playerIndex] || {};
												const hasMadeItIn = playerHoleScores.madeItIn;
												const madeItInShotType = playerHoleScores.madeItInShotType;

												// Determine if player should be greyed out and buttons disabled
												let greyOut = false;
												let buttonsDisabled = false;
												if (hasMadeItIn) {
													const madeItInShotIndex = shotTypes.indexOf(madeItInShotType);
													const currentShotIndex = shotTypes.indexOf(shotType);
													if (currentShotIndex > madeItInShotIndex) {
														greyOut = true;
														buttonsDisabled = true;
													}
												}

												const playerSelection = playerSelections[playerIndex];

												return (
													<View
														key={playerIndex}
														style={{
															flexDirection: 'row',
															flex: 1 / players.length,
															justifyContent: 'flex-start',
															alignItems: 'flex-start',
														}}
													>
														{/* Player Name Column */}
														<View
															style={{
																flex: 2.2,
																borderRightWidth: 1,
																height: '100%',
																overflow: 'hidden',
																justifyContent: 'flex-start',
																alignItems: 'flex-start',
															}}
														>
															<View style={[styles.playerRow, greyOut && styles.greyOut]}>
																{/* Show avatar only if no selection has been made */}
																{!playerSelection && !greyOut && (
																	<>
																		{player.avatar || player.avatar === 0 ? (
																			<Image
																				source={avatars[player.avatar].image} // Updated avatar source
																				style={[
																					styles.avatarSmall,
																					// { marginRight: -7.5, marginLeft: -12.5 },
																				]}
																			/>
																		) : (
																			<View style={styles.avatarPlaceholderSmall}>
																				<Text style={styles.avatarInitial}>
																					{player.name.charAt(0)}
																				</Text>
																			</View>
																		)}
																	</>
																)}
																<Text
																	style={[styles.playerName, { fontSize: holeTextFontSize / 2 }]}
																>
																	{player.name}
																</Text>
															</View>
														</View>
														{/* IN Button / Throws */}
														{[0, 1, 2].map(colIdx => (
															<View
																key={colIdx}
																style={{
																	flex: 1,
																	justifyContent: 'center',
																	alignItems: 'center',
																	height: '100%',
																	borderRightWidth: colIdx < 2 ? 1 : 0,
																}}
															>
																{colIdx === 0 && (
																	<TouchableOpacity
																		style={[
																			styles.resultButton,
																			{ backgroundColor: '#10F30D', opacity: greyOut ? 0 : 1 },
																		]}
																		onPress={() => handleSelection(playerIndex, 'in')}
																		disabled={greyOut}
																	>
																		{!buttonsDisabled && playerSelection === 'in' ? (
																			player.avatar || player.avatar === 0 ? (
																				<Image
																					source={avatars[player.avatar].image} // Updated avatar source
																					style={[styles.avatarSmall, { marginRight: 0 }]}
																				/>
																			) : (
																				<View style={styles.avatarPlaceholderSmall}>
																					<Text style={styles.avatarInitial}>
																						{player.name.charAt(0)}
																					</Text>
																				</View>
																			)
																		) : (
																			<Text style={styles.resultButtonText}>IN</Text>
																		)}
																	</TouchableOpacity>
																)}
																{colIdx === 1 && (
																	<TouchableOpacity
																		style={[
																			styles.resultButton,
																			{ backgroundColor: '#F4DB0D', opacity: greyOut ? 0 : 1 },
																		]}
																		onPress={() => handleSelection(playerIndex, 'on')}
																		disabled={greyOut}
																	>
																		{!buttonsDisabled && playerSelection === 'on' ? (
																			player.avatar || player.avatar === 0 ? (
																				<Image
																					source={avatars[player.avatar].image} // Updated avatar source
																					style={[styles.avatarSmall, { marginRight: 0 }]}
																				/>
																			) : (
																				<View style={styles.avatarPlaceholderSmall}>
																					<Text style={styles.avatarInitial}>
																						{player.name.charAt(0)}
																					</Text>
																				</View>
																			)
																		) : (
																			<Text style={styles.resultButtonText}>ON</Text>
																		)}
																	</TouchableOpacity>
																)}
																{colIdx === 2 && (
																	<TouchableOpacity
																		style={[
																			styles.resultButton,
																			{ backgroundColor: '#F84413', opacity: greyOut ? 0 : 1 },
																		]}
																		onPress={() => handleSelection(playerIndex, 'missed')}
																		disabled={greyOut}
																	>
																		{!buttonsDisabled && playerSelection === 'missed' ? (
																			player.avatar || player.avatar === 0 ? (
																				<Image
																					source={avatars[player.avatar].image} // Updated avatar source
																					style={[styles.avatarSmall, { marginRight: 0 }]}
																				/>
																			) : (
																				<View style={styles.avatarPlaceholderSmall}>
																					<Text style={styles.avatarInitial}>
																						{player.name.charAt(0)}
																					</Text>
																				</View>
																			)
																		) : (
																			<Text style={styles.resultButtonText}>OFF</Text>
																		)}
																	</TouchableOpacity>
																)}
															</View>
														))}
													</View>
												);
											})}
										</View>
										<View style={{ width: '100%', height: '20%', zIndex: 10 }}>
											<View style={styles.navigationButtons}>
												<ArrowButton
													direction="back"
													text={shotType === 'drive' ? 'Last Hole' : 'Back'}
													disabled={currentHole === 1 && shotType === 'drive'}
													onPress={previousStep}
													textStyle={{ fontSize: holeTextFontSize / 2.4 }}
												/>
												<View
													style={{
														width: '29%',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<TouchableOpacity
														style={styles.standingsButton}
														tabIndex="0"
														onTouchStart={() => {
															setStandingsVisible(true);
														}}
														onTouchEnd={() => {
															setStandingsVisible(false);
														}}
														onPressIn={() => {
															setStandingsVisible(true);
														}}
														onPressOut={() => {
															const os = getOS();
															if (os !== 'Android' && os !== 'Linux') {
																setStandingsVisible(false);
															}
														}}
													>
														<View>
															<View style={{ alignItems: 'center' }}>
																<Text
																	style={[
																		styles.menuButtonText,
																		{
																			letterSpacing: '-0.3px',
																			fontSize: holeTextFontSize / 2.35,
																		},
																	]}
																>
																	Standings
																</Text>
																<Text
																	style={[
																		styles.subscriptText,
																		{ fontSize: holeTextFontSize / 2.72 },
																	]}
																>
																	Press<Text style={{ marginHorizontal: 1.5 }}>&</Text>Hold
																</Text>
															</View>
														</View>
													</TouchableOpacity>
												</View>
												<ArrowButton
													direction="forward"
													text={shotType === 'putt' ? 'Next Hole' : 'Next Box'}
													disabled={!allPlayersSelected()}
													onPress={nextStep}
													textStyle={{ fontSize: holeTextFontSize / 2.4 }}
												/>
											</View>
										</View>
									</View>
								</Animated.View>

								{/* Standings Overlay */}

								{standingsVisible && (
									<Animated.View
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											opacity: standingsOpacity,
											backgroundColor: 'transparent', // Solid background to cover images
											justifyContent: 'flex-start',
											alignItems: 'center',
											userSelect: 'none',
										}}
									>
										<View
											style={{
												width: '84.4%',
												height: '75.5%',
												marginTop: '40.5%',
												justifyContent: 'flex-start',
												alignItems: 'center',
												userSelect: 'none',
											}}
										>
											{/* Header */}
											<View
												style={{
													width: '100%',
													height: '16%',
													marginTop: '2%',
													flexDirection: 'row',
													borderBottomWidth: 1,
													userSelect: 'none',
												}}
											>
												<View
													style={{
														flex: 2.2,
														borderRightWidth: 1,
														justifyContent: 'center',
														userSelect: 'none',
													}}
													onLayout={handleLayout}
												>
													<Text
														style={[styles.dynamicText, { fontSize: holeTextFontSize / 1.3 }]}
													>
														Standings
													</Text>
												</View>
												{['Strokes', 'Board Credits', 'Final Score'].map((label, idx) => (
													<View
														key={idx}
														style={[
															styles.columnContainer,
															{
																flex: 1,
																borderRightWidth: idx < 2 ? 1 : 0,
																userSelect: 'none',
															},
														]}
													>
														<Text style={[styles.dynamicTextComic, { fontSize }]}>
															{label}
														</Text>
													</View>
												))}
											</View>
											{/* Separator */}
											<View
												style={{
													height: '2%',
													width: '100%',
													flexDirection: 'row',
													justifyContent: 'flex-start',
													userSelect: 'none',
												}}
											>
												<View
													style={{
														flex: 2.2,
														borderRightWidth: 1,
														height: '100%',
													}}
												/>
												<View
													style={{
														flex: 1,
														borderRightWidth: 1,
														height: '100%',
													}}
												/>
												<View
													style={{
														flex: 1,
														borderRightWidth: 1,
														height: '100%',
													}}
												/>
												<View
													style={{
														flex: 1,
														height: '100%',
													}}
												/>
											</View>
											{/* Player Scores */}
											<View
												style={{
													width: '100%',
													height: '60%',
													flexDirection: 'column',
													justifyContent: 'space-evenly',
													borderBottomWidth: 1,
													userSelect: 'none',
												}}
											>
												{sortedPlayers.map((player, index) => {
													const playerScore = playerScores[index];
													return (
														<View
															key={index}
															style={{
																flexDirection: 'row',
																flex: 1 / players.length,
																justifyContent: 'flex-start',
																alignItems: 'flex-start',
																userSelect: 'none',
															}}
														>
															{/* Player Name Column */}
															<View
																style={{
																	flex: 2.2,
																	borderRightWidth: 1,
																	height: '100%',
																	overflow: 'hidden',
																	justifyContent: 'flex-start',
																	alignItems: 'flex-start',
																	userSelect: 'none',
																}}
															>
																<View style={styles.playerRow}>
																	{player.player.avatar || player.player.avatar === 0 ? (
																		<Image
																			source={avatars[player.player.avatar].image} // Updated avatar source
																			style={[
																				styles.avatarSmall,
																				// { marginRight: -7.5, marginLeft: -12.5 },
																			]}
																		/>
																	) : (
																		<View style={styles.avatarPlaceholderSmall}>
																			<Text style={styles.avatarInitial}>
																				{player.player.name.charAt(0)}
																			</Text>
																		</View>
																	)}
																	<Text
																		style={[
																			styles.playerName,
																			{ fontSize: holeTextFontSize / 2 },
																		]}
																	>
																		{player.player.name}
																	</Text>
																</View>
															</View>
															{/* Player Scores */}
															{[
																playerScore.totalShots,
																playerScore.totalBoardCredits,
																playerScore.finalScore,
															].map((score, idx) => (
																<View
																	key={idx}
																	style={{
																		flex: 1,
																		justifyContent: 'center',
																		alignItems: 'center',
																		height: '100%',
																		borderRightWidth: idx < 2 ? 1 : 0,
																		userSelect: 'none',
																	}}
																>
																	<Text style={[styles.resultButtonText, { fontSize: 22 }]}>
																		{score}
																	</Text>
																</View>
															))}
														</View>
													);
												})}
											</View>
										</View>
									</Animated.View>
								)}
							</View>
						</ImageBackground>
					</View>
				</>
			) : (
				// Game Finished - Show Final Scores
				<View style={{ width: '100%', height: '100%' }}>
					{finalScores && (
						<FinalScores finalScores={finalScores} onStartNewGame={startNewGame} />
					)}
				</View>
			)}
		</ImageBackground>
	);
};

const styles = StyleSheet.create({
	container: {
		height: '100%',
		width: '100%',
		backgroundColor: '#282c34',
		userSelect: 'none',
		alignItems: 'center',
	},
	loadingContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#282c34',
	},
	navigationButtons: {
		flexDirection: 'row',
		userSelect: 'none',
		justifyContent: 'space-evenly',
		width: '100%',
		height: '100%',
		alignItems: 'center',
	},
	menuButton: {
		backgroundColor: '#98CF12',
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		width: 100,
		userSelect: 'none',
		borderWidth: 2.4,
		borderRadius: 10,
		cursor: 'pointer', // Ensures pointer cursor
	},
	standingsButton: {
		backgroundColor: '#E3A011',
		padding: 6,
		justifyContent: 'center',
		alignItems: 'center',
		width: '92.5%',
		borderWidth: 2.4,
		borderRadius: 10,
		userSelect: 'none',
		cursor: 'pointer', // Ensures pointer cursor
		userSelect: 'none',
		touchAction: 'none',
	},
	menuButtonText: {
		fontFamily: 'Nunito_900Black',
		fontSize: 16,
		letterSpacing: '-1.2px',
		textAlign: 'center',
		userSelect: 'none',
		color: '#000',
	},
	subscriptText: {
		fontFamily: 'Nunito_900Black',
		fontSize: 10,
		letterSpacing: '-.1.4px',
		textAlign: 'center',
		userSelect: 'none',
		color: '#000',
	},
	header: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '10%',
		marginBottom: 0,
		userSelect: 'none',
		padding: 20,
	},
	holeText: {
		fontSize: 24,
		color: '#fff',
		fontWeight: 'bold',
		userSelect: 'none',
	},
	logo: {
		width: 120,
		height: 120,
		userSelect: 'none',
		resizeMode: 'contain',
		objectFit: 'contain',
	},
	dynamicText: {
		fontFamily: 'Nunito_900Black',
		textAlign: 'center',
		justifyContent: 'center',
		userSelect: 'none',
		letterSpacing: '-2px',
	},
	dynamicTextComic: {
		fontFamily: 'Nunito_900Black',
		letterSpacing: '-0.85px',
		transform: [{ rotate: '-8deg' }],
		lineHeight: 13,
		textAlign: 'center',
		userSelect: 'none',
	},
	columnContainer: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		userSelect: 'none',
	},
	playerRow: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingHorizontal: 10,
		userSelect: 'none',
		height: '100%',
	},
	avatarSmall: {
		width: 30,
		height: 30,
		marginRight: 15,
	},
	avatarPlaceholderSmall: {
		width: 30,
		height: 30,
		borderRadius: 15,
		backgroundColor: '#555',
		userSelect: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 10,
	},
	avatarInitial: {
		fontSize: 24,
		color: 'black',
		userSelect: 'none',
	},
	playerName: {
		flex: 1,
		color: 'black',
		fontFamily: 'Nunito_900Black',
		textWrap: 'wrap',
		textAlign: 'left',
		userSelect: 'none',
	},
	greyOut: {
		opacity: 0.5,
	},
	resultButton: {
		height: 30,
		width: 30,
		justifyContent: 'center',
		alignItems: 'center',
		borderColor: 'black',
		userSelect: 'none',
		borderWidth: 2,
	},
	resultButtonText: {
		fontSize: 14,
		fontWeight: 'bold',
		userSelect: 'none',
		color: '#000',
	},
});

export default Scorecard;
