// contexts/GameContext.js
import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
	const [gameMode, setGameMode] = useState(null);
	const [players, setPlayers] = useState([]);
	const [scores, setScores] = useState({});
	const [currentHole, setCurrentHole] = useState(1);
	const [shotType, setShotType] = useState('drive'); // Initialize with 'drive'
	const [isLoaded, setIsLoaded] = useState(false); // Loading state
	const [gameFinished, setGameFinished] = useState(false); // Game finished state

	// Function to load game data from AsyncStorage
	const loadGame = async () => {
		try {
			const savedScores = await AsyncStorage.getItem('gameScores');
			const savedCurrentHole = await AsyncStorage.getItem('gameCurrentHole');
			const savedShotType = await AsyncStorage.getItem('gameShotType');
			const savedPlayers = await AsyncStorage.getItem('gamePlayers');
			const savedGameMode = await AsyncStorage.getItem('gameMode');
			const savedGameFinished = await AsyncStorage.getItem('gameFinished');

			if (savedScores) setScores(JSON.parse(savedScores));
			if (savedCurrentHole) setCurrentHole(JSON.parse(savedCurrentHole));
			if (savedShotType) setShotType(JSON.parse(savedShotType));
			if (savedPlayers) setPlayers(JSON.parse(savedPlayers));
			if (savedGameMode) setGameMode(JSON.parse(savedGameMode));
			if (savedGameFinished) setGameFinished(JSON.parse(savedGameFinished));

			setIsLoaded(true); // Mark as loaded
		} catch (error) {
			console.error('Failed to load game data:', error);
		}
	};

	// Load game data when the provider mounts
	useEffect(() => {
		loadGame();
	}, []);

	// Save game data to AsyncStorage whenever relevant states change
	useEffect(() => {
		if (!isLoaded) return; // Prevent saving until loaded

		const saveGameContent = async () => {
			try {
				await AsyncStorage.setItem('gameScores', JSON.stringify(scores));
				await AsyncStorage.setItem('gameCurrentHole', JSON.stringify(currentHole));
				await AsyncStorage.setItem('gameShotType', JSON.stringify(shotType)); // Save shotType
				await AsyncStorage.setItem('gamePlayers', JSON.stringify(players));
				await AsyncStorage.setItem('gameMode', JSON.stringify(gameMode));
				await AsyncStorage.setItem('gameFinished', JSON.stringify(gameFinished));
			} catch (error) {
				console.error('Failed to save game data:', error);
			}
		};
		saveGameContent();
	}, [gameMode, players, scores, currentHole, shotType, isLoaded]); // Include shotType in dependencies

	return (
		<GameContext.Provider
			value={{
				gameMode,
				setGameMode,
				players,
				setPlayers,
				scores,
				setScores,
				currentHole,
				setCurrentHole,
				shotType, // Expose shotType
				setShotType, // Expose setShotType
				isLoaded, // Expose loading state
				gameFinished,
				setGameFinished,
			}}
		>
			{children}
		</GameContext.Provider>
	);
};
