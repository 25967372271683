// // components/ConfirmationModal.js
// import React from 'react';
// import {
// 	View,
// 	Text,
// 	Modal,
// 	StyleSheet,
// 	TouchableOpacity,
// 	Dimensions,
// } from 'react-native';

// const ConfirmationModal = ({
// 	visible,
// 	message,
// 	onConfirm,
// 	onCancel,
// 	confirmText = 'Yes',
// 	cancelText = 'No',
// }) => {
// 	return (
// 		<Modal
// 			transparent={true}
// 			visible={visible}
// 			animationType="fade"
// 			onRequestClose={onCancel}
// 		>
// 			<View style={styles.overlay}>
// 				<View style={styles.modalContainer}>
// 					<Text style={styles.message}>{message}</Text>
// 					<View style={styles.buttonContainer}>
// 						<TouchableOpacity
// 							style={[styles.button, styles.cancelButton]}
// 							onPress={onCancel}
// 						>
// 							<Text style={styles.buttonText}>{cancelText}</Text>
// 						</TouchableOpacity>
// 						<TouchableOpacity
// 							style={[styles.button, styles.confirmButton]}
// 							onPress={onConfirm}
// 						>
// 							<Text style={styles.buttonText}>{confirmText}</Text>
// 						</TouchableOpacity>
// 					</View>
// 				</View>
// 			</View>
// 		</Modal>
// 	);
// };

// const styles = StyleSheet.create({
// 	overlay: {
// 		flex: 1,
// 		backgroundColor: 'rgba(0, 0, 0, 0.5)',
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 	},
// 	modalContainer: {
// 		width: '80%',
// 		maxWidth: 400,
// 		backgroundColor: '#fff',
// 		borderRadius: 10,
// 		padding: 20,
// 		// Shadow for iOS
// 		shadowColor: '#000',
// 		shadowOffset: { width: 0, height: 2 },
// 		shadowOpacity: 0.25,
// 		shadowRadius: 4,
// 		// Elevation for Android
// 		elevation: 5,
// 		// Center content
// 		alignItems: 'center',
// 	},
// 	message: {
// 		fontSize: 18,
// 		marginBottom: 20,
// 		textAlign: 'center',
// 		color: '#333',
// 	},
// 	buttonContainer: {
// 		flexDirection: 'row',
// 		width: '100%',
// 		justifyContent: 'space-around',
// 	},
// 	button: {
// 		width: '40%',
// 		padding: 12,
// 		borderRadius: 5,
// 		alignItems: 'center',
// 	},
// 	cancelButton: {
// 		backgroundColor: '#ccc',
// 	},
// 	confirmButton: {
// 		backgroundColor: '#E53935',
// 	},
// 	buttonText: {
// 		color: '#fff',
// 		fontSize: 16,
// 	},
// });

// export default ConfirmationModal;

// components/ConfirmationModal.js
import React from 'react';
import {
	View,
	Text,
	Modal,
	StyleSheet,
	TouchableOpacity,
	Dimensions,
} from 'react-native';

const ConfirmationModal = ({
	visible,
	message,
	onConfirm,
	onCancel,
	confirmText = 'Yes',
	cancelText = 'No',
}) => {
	return (
		<Modal
			transparent={true}
			visible={visible}
			animationType="fade"
			onRequestClose={onCancel}
		>
			<View style={styles.overlay}>
				<View style={styles.modalContainer}>
					<Text style={styles.message}>{message}</Text>
					<View style={styles.buttonContainer}>
						<TouchableOpacity
							style={[styles.button, styles.cancelButton]}
							onPress={onCancel}
						>
							<Text style={styles.buttonText}>{cancelText}</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={[styles.button, styles.confirmButton]}
							onPress={onConfirm}
						>
							<Text style={styles.buttonText}>{confirmText}</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	overlay: {
		flex: 1,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalContainer: {
		width: '80%',
		maxWidth: 400,
		backgroundColor: '#fff',
		borderRadius: 10,
		padding: 20,
		// Shadow for iOS
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 4,
		// Elevation for Android
		elevation: 5,
		// Center content
		alignItems: 'center',
	},
	message: {
		fontSize: 18,
		marginBottom: 20,
		textAlign: 'center',
		color: '#333',
	},
	buttonContainer: {
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-around',
	},
	button: {
		width: '40%',
		padding: 12,
		borderRadius: 5,
		alignItems: 'center',
	},
	cancelButton: {
		backgroundColor: '#ccc',
	},
	confirmButton: {
		backgroundColor: '#E53935',
	},
	buttonText: {
		color: '#fff',
		fontSize: 16,
	},
});

export default ConfirmationModal;
