// // components/PlayerSetup.js
// import React, { useContext, useState } from 'react';
// import { View, Text, StyleSheet, Image, ImageBackground } from 'react-native';
// import { useNavigate } from 'react-router-dom';
// import { GameContext } from '../contexts/GameContext';
// import Button from './Button';
// import PlayersDisplay from './PlayersDisplay';
// import ConfirmationModal from './ConfirmationModal'; // Import ConfirmationModal
// import EditPlayerModal from './EditPlayerModal';

// export default function PlayerSetup() {
// 	const { players, setPlayers, setScores, setCurrentHole } =
// 		useContext(GameContext);
// 	const [modalVisible, setModalVisible] = useState(false);
// 	const [editingPlayerIndex, setEditingPlayerIndex] = useState(null);
// 	const [playerName, setPlayerName] = useState('');
// 	const [selectedAvatar, setSelectedAvatar] = useState(null); // State for selected avatar when adding

// 	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
// 		useState(false); // Confirmation modal for deleting a player
// 	const [resetAllConfirmationVisible, setResetAllConfirmationVisible] =
// 		useState(false); // Confirmation modal for resetting all players

// 	const navigate = useNavigate();

// 	// Function to handle avatar selection
// 	const handleAvatarSelect = avatarId => {
// 		setSelectedAvatar(avatarId);
// 	};

// 	// Function to handle adding a new player
// 	const handleAddPlayer = () => {
// 		if (players.length >= 6) {
// 			alert('Maximum Players Reached. You cannot add more than 6 players.');
// 			return;
// 		}
// 		setModalVisible(true);
// 		setEditingPlayerIndex(null);
// 		setPlayerName('');
// 		setSelectedAvatar(null); // Reset selected avatar
// 	};

// 	// Function to handle editing an existing player
// 	const handleEditPlayer = index => {
// 		setModalVisible(true);
// 		setEditingPlayerIndex(index);
// 		setPlayerName(players[index].name);
// 		setSelectedAvatar(players[index].avatar);
// 	};

// 	// Function to initiate deletion (opens confirmation modal)
// 	const handleInitiateDeletePlayer = () => {
// 		setDeleteConfirmationVisible(true);
// 	};

// 	// Function to confirm deletion of a single player
// 	const handleConfirmDelete = () => {
// 		const newPlayers = [...players];
// 		newPlayers.splice(editingPlayerIndex, 1);
// 		setPlayers(newPlayers);
// 		setModalVisible(false);
// 		setDeleteConfirmationVisible(false);
// 	};

// 	// Function to cancel deletion
// 	const handleCancelDelete = () => {
// 		setDeleteConfirmationVisible(false);
// 	};

// 	// Function to save a player (add or update)
// 	const handleSavePlayer = () => {
// 		if (playerName.trim() === '') {
// 			alert('Invalid Name. Please enter a valid name.');
// 			return;
// 		}
// 		if (selectedAvatar === null) {
// 			alert('No Avatar Selected. Please select an avatar.');
// 			return;
// 		}

// 		// Check if the selected avatar is already taken by another player
// 		const isAvatarTaken = players.some(
// 			(player, idx) =>
// 				player.avatar === selectedAvatar && idx !== editingPlayerIndex // Allow if it's the same player being edited
// 		);
// 		if (isAvatarTaken) {
// 			alert(
// 				'Avatar Unavailable. This avatar is already taken. Please select another one.'
// 			);
// 			return;
// 		}

// 		const newPlayers = [...players];
// 		const playerData = { name: playerName.trim(), avatar: selectedAvatar };
// 		if (editingPlayerIndex !== null) {
// 			newPlayers[editingPlayerIndex] = playerData;
// 		} else {
// 			newPlayers.push(playerData);
// 		}
// 		setPlayers(newPlayers);
// 		setModalVisible(false);
// 	};

// 	// Function to cancel the modal
// 	const handleCancel = () => {
// 		setModalVisible(false);
// 	};

// 	// Function to start the game
// 	const startGame = () => {
// 		setScores({});
// 		setCurrentHole(1);
// 		if (players.length === 0) {
// 			alert('No Players. Please add at least one player.');
// 			return;
// 		}
// 		navigate('/scorecard');
// 	};

// 	// Function to handle resetting all players (shows confirmation modal)
// 	const handleResetAllPlayers = () => {
// 		setResetAllConfirmationVisible(true);
// 	};

// 	// Function to confirm resetting all players
// 	const handleConfirmResetAllPlayers = () => {
// 		setPlayers([]);
// 		setResetAllConfirmationVisible(false);
// 	};

// 	// Function to cancel resetting all players
// 	const handleCancelResetAllPlayers = () => {
// 		setResetAllConfirmationVisible(false);
// 	};

// 	// Determine which avatars are already selected by other players
// 	const getDisabledAvatars = () => {
// 		if (editingPlayerIndex !== null) {
// 			// When editing, exclude the avatar of the player being edited
// 			return players
// 				.filter((_, idx) => idx !== editingPlayerIndex)
// 				.map(player => player.avatar);
// 		}
// 		// When adding, all selected avatars are disabled
// 		return players.map(player => player.avatar);
// 	};

// 	return (
// 		<ImageBackground
// 			source={require('../assets/images/textured_background2.png')}
// 			style={styles.container}
// 			resizeMode="cover"
// 		>
// 			{/* Header Section */}
// 			<View style={styles.header}>
// 				{/* <Text style={styles.title}>Add Players</Text> */}
// 				{/* <Image
// 					source={require('../assets/images/logo.webp')}
// 					style={styles.logo}
// 					resizeMode="contain"
// 				/> */}
// 			</View>

// 			<View style={styles.contentContainer}>
// 				<View style={styles.playerManagementContainer}>
// 					{/* Add Player Button */}
// 					<Button
// 						title="Add New Player"
// 						onPress={handleAddPlayer}
// 						style={styles.addPlayerButton}
// 					/>

// 					{/* Reset All Players Button */}
// 					<Button
// 						title="Reset All Players"
// 						onPress={handleResetAllPlayers}
// 						style={styles.resetButton}
// 					/>

// 					{/* Players Display */}
// 					<PlayersDisplay onEditPlayer={handleEditPlayer} />
// 				</View>

// 				{/* Start Game Button */}
// 				<Button title="Start Game" onPress={startGame} />
// 			</View>

// 			{/* Modal for Adding/Editing Players */}
// 			<EditPlayerModal
// 				modalVisible={modalVisible}
// 				handleCancel={handleCancel}
// 				handleSavePlayer={handleSavePlayer}
// 				handleDeletePlayer={handleInitiateDeletePlayer}
// 				editingPlayerIndex={editingPlayerIndex}
// 				playerName={playerName}
// 				setPlayerName={setPlayerName}
// 				selectedAvatar={selectedAvatar}
// 				handleAvatarSelect={handleAvatarSelect}
// 				getDisabledAvatars={getDisabledAvatars}
// 			/>

// 			{/* Confirmation Modal for Deleting a Single Player */}
// 			<ConfirmationModal
// 				visible={deleteConfirmationVisible}
// 				message="Are you sure you want to delete this player?"
// 				onConfirm={handleConfirmDelete}
// 				onCancel={handleCancelDelete}
// 				confirmText="Delete"
// 				cancelText="Cancel"
// 			/>

// 			{/* Confirmation Modal for Resetting All Players */}
// 			<ConfirmationModal
// 				visible={resetAllConfirmationVisible}
// 				message="Are you sure you want to reset all players?"
// 				onConfirm={handleConfirmResetAllPlayers}
// 				onCancel={handleCancelResetAllPlayers}
// 				confirmText="Reset"
// 				cancelText="Cancel"
// 			/>
// 		</ImageBackground>
// 	);
// }

// // Stylesheet for the component
// const styles = StyleSheet.create({
// 	container: {
// 		width: '100%',
// 		height: '100%',
// 		backgroundColor: '#282c34',
// 		alignItems: 'center',
// 		paddingTop: 0,
// 	},
// 	header: {
// 		width: '100%',
// 		flexDirection: 'row',
// 		justifyContent: 'space-between',
// 		alignItems: 'center',
// 		// height: 160,
// 		marginBottom: 40,
// 		paddingHorizontal: 50,
// 	},
// 	logo: {
// 		height: 110,
// 		width: 110,
// 	},
// 	title: {
// 		fontSize: 34,
// 		color: 'white',
// 		fontWeight: 'bold',
// 	},
// 	contentContainer: {
// 		flexDirection: 'column',
// 		justifyContent: 'space-between',
// 		alignItems: 'center',
// 		width: '100%',
// 		height: 'calc(100% - 220px)',
// 		marginBottom: 20,
// 	},
// 	playerManagementContainer: {
// 		width: '100%',
// 		alignItems: 'center',
// 	},
// 	resetButton: {
// 		backgroundColor: 'red',
// 		marginTop: 10,
// 		height: 50,
// 		borderRadius: 10,
// 		// width: '80%',
// 	},
// 	// ... (Rest of your styles)
// 	// Modal styles
// 	modalOverlay: {
// 		height: '100%',
// 		width: '100%',
// 		backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent background
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 	},
// 	modalContainer: {
// 		width: '90%',
// 		maxHeight: '90%',
// 		backgroundColor: '#fff',
// 		borderRadius: 20,
// 		padding: 20,
// 		// Shadow for iOS
// 		shadowColor: '#000',
// 		shadowOffset: { width: 0, height: 2 },
// 		shadowOpacity: 0.25,
// 		shadowRadius: 4,
// 		elevation: 5,
// 	},
// 	modalScrollContent: {
// 		paddingBottom: 20,
// 	},
// 	modalHeader: {
// 		flexDirection: 'row',
// 		justifyContent: 'space-between',
// 		alignItems: 'center',
// 		marginBottom: 20,
// 	},
// 	modalTitle: {
// 		fontSize: 20,
// 		fontWeight: 'bold',
// 		color: '#333',
// 	},
// 	modalLabel: {
// 		fontSize: 16,
// 		marginBottom: 5,
// 		color: '#333',
// 	},
// 	nameInputContainer: {
// 		flexDirection: 'row',
// 		alignItems: 'center',
// 		marginBottom: 20,
// 		borderWidth: 1,
// 		borderColor: '#ccc',
// 		borderRadius: 10,
// 		paddingHorizontal: 10,
// 		paddingVertical: 5,
// 	},
// 	avatarImage: {
// 		width: 40,
// 		height: 40,
// 		marginRight: 10,
// 		borderRadius: 20,
// 	},
// 	modalInputWithAvatar: {
// 		flex: 1,
// 		height: 40,
// 		fontSize: 16,
// 		color: '#333',
// 	},
// 	inputWithAvatarPadding: {
// 		paddingLeft: 0, // Adjust padding if avatar is present
// 	},
// 	avatarSelectorContainer: {
// 		marginBottom: 20,
// 	},
// 	modalButtons: {
// 		flexDirection: 'row',
// 		justifyContent: 'space-evenly',
// 		marginTop: 30,
// 		width: '100%',
// 	},
// 	cancelButton: {
// 		width: '40%',
// 		backgroundColor: '#ccc',
// 	},
// 	saveButton: {
// 		width: '40%',
// 		backgroundColor: '#4CAF50',
// 	},
// });

// components/PlayerSetup.js
import React, { useContext, useState } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Image,
	ImageBackground,
	TouchableOpacity,
} from 'react-native'; // Added TouchableOpacity
import { useNavigate } from 'react-router-dom';
import { GameContext } from '../contexts/GameContext';
import Button from './Button';
import PlayersDisplay from './PlayersDisplay';
import ConfirmationModal from './ConfirmationModal'; // Import ConfirmationModal
import EditPlayerModal from './EditPlayerModal';

export default function PlayerSetup() {
	const {
		players,
		setPlayers,
		setScores,
		setCurrentHole,
		setShotType,
		setGameFinished,
	} = useContext(GameContext);
	const [modalVisible, setModalVisible] = useState(false);
	const [editingPlayerIndex, setEditingPlayerIndex] = useState(null);
	const [playerName, setPlayerName] = useState('');
	const [selectedAvatar, setSelectedAvatar] = useState(null); // State for selected avatar when adding

	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false); // Confirmation modal for deleting a player
	const [resetAllConfirmationVisible, setResetAllConfirmationVisible] =
		useState(false); // Confirmation modal for resetting all players

	const navigate = useNavigate();

	// Function to handle avatar selection
	const handleAvatarSelect = avatarId => {
		setSelectedAvatar(avatarId);
	};

	// Function to handle adding a new player
	const handleAddPlayer = () => {
		if (players.length >= 6) {
			alert('Maximum Players Reached. You cannot add more than 6 players.');
			return;
		}
		setModalVisible(true);
		setEditingPlayerIndex(null);
		setPlayerName('');
		setSelectedAvatar(null); // Reset selected avatar
	};

	// Function to handle editing an existing player
	const handleEditPlayer = index => {
		setModalVisible(true);
		setEditingPlayerIndex(index);
		setPlayerName(players[index].name);
		setSelectedAvatar(players[index].avatar);
	};

	// Function to initiate deletion (opens confirmation modal)
	const handleInitiateDeletePlayer = () => {
		setDeleteConfirmationVisible(true);
	};

	// Function to confirm deletion of a single player
	const handleConfirmDelete = () => {
		const newPlayers = [...players];
		newPlayers.splice(editingPlayerIndex, 1);
		setPlayers(newPlayers);
		setModalVisible(false);
		setDeleteConfirmationVisible(false);
	};

	// Function to cancel deletion
	const handleCancelDelete = () => {
		setDeleteConfirmationVisible(false);
	};

	// Function to save a player (add or update)
	const handleSavePlayer = () => {
		if (playerName.trim() === '') {
			alert('Invalid Name. Please enter a valid name.');
			return;
		}
		if (selectedAvatar === null) {
			alert('No Avatar Selected. Please select an avatar.');
			return;
		}

		// Check if the selected avatar is already taken by another player
		const isAvatarTaken = players.some(
			(player, idx) =>
				player.avatar === selectedAvatar && idx !== editingPlayerIndex // Allow if it's the same player being edited
		);
		if (isAvatarTaken) {
			alert(
				'Avatar Unavailable. This avatar is already taken. Please select another one.'
			);
			return;
		}

		const newPlayers = [...players];
		const playerData = { name: playerName.trim(), avatar: selectedAvatar };
		if (editingPlayerIndex !== null) {
			newPlayers[editingPlayerIndex] = playerData;
		} else {
			newPlayers.push(playerData);
		}
		setPlayers(newPlayers);
		setModalVisible(false);
	};

	// Function to cancel the modal
	const handleCancel = () => {
		setModalVisible(false);
	};

	// Function to start the game
	const startGame = () => {
		// setScores({});
		// setCurrentHole(1);
		if (players.length === 0) {
			alert('No Players. Please add at least one player.');
			return;
		}
		navigate('/scorecard');
	};

	// Function to handle resetting all players (shows confirmation modal)
	const handleResetAllPlayers = () => {
		setResetAllConfirmationVisible(true);
	};

	// Function to confirm resetting all players
	const handleConfirmResetAllPlayers = () => {
		setPlayers([]);
		setScores({});
		setCurrentHole(1);
		setShotType('drive');
		setGameFinished(false);
		setResetAllConfirmationVisible(false);
	};

	// Function to cancel resetting all players
	const handleCancelResetAllPlayers = () => {
		setResetAllConfirmationVisible(false);
	};

	// Function to handle Back button press
	const handleBack = () => {
		// navigate(-1);
		navigate('/');
	};

	// Determine which avatars are already selected by other players
	const getDisabledAvatars = () => {
		if (editingPlayerIndex !== null) {
			// When editing, exclude the avatar of the player being edited
			return players
				.filter((_, idx) => idx !== editingPlayerIndex)
				.map(player => player.avatar);
		}
		// When adding, all selected avatars are disabled
		return players.map(player => player.avatar);
	};

	return (
		<ImageBackground
			source={require('../assets/images/textured_background2.png')}
			style={styles.container}
			resizeMode="cover"
		>
			{/* Header Section */}
			<View style={styles.header}>
				<TouchableOpacity
					style={styles.menuButton} // Reusing menuButton style
					onPress={handleBack}
				>
					<Text style={styles.menuButtonText}>Back</Text>
				</TouchableOpacity>
				<Image
					source={require('../assets/images/logo.webp')}
					style={styles.logo}
					resizeMode="contain"
				/>
			</View>

			<View style={styles.contentContainer}>
				<View style={styles.playerManagementContainer}>
					{/* Add Player Button */}
					<Button
						title="Add New Player"
						onPress={handleAddPlayer}
						style={styles.addPlayerButton}
					/>

					{/* Reset All Players Button */}
					<Button
						title="Reset All Players"
						onPress={handleResetAllPlayers}
						style={styles.resetButton}
					/>

					{/* Players Display */}
					<PlayersDisplay onEditPlayer={handleEditPlayer} />
				</View>

				{/* Start Game Button */}
				<Button title="Start Game" onPress={startGame} />
			</View>

			{/* Modal for Adding/Editing Players */}
			<EditPlayerModal
				modalVisible={modalVisible}
				handleCancel={handleCancel}
				handleSavePlayer={handleSavePlayer}
				handleDeletePlayer={handleInitiateDeletePlayer}
				editingPlayerIndex={editingPlayerIndex}
				playerName={playerName}
				setPlayerName={setPlayerName}
				selectedAvatar={selectedAvatar}
				handleAvatarSelect={handleAvatarSelect}
				getDisabledAvatars={getDisabledAvatars}
			/>

			{/* Confirmation Modal for Deleting a Single Player */}
			<ConfirmationModal
				visible={deleteConfirmationVisible}
				message="Are you sure you want to delete this player?"
				onConfirm={handleConfirmDelete}
				onCancel={handleCancelDelete}
				confirmText="Delete"
				cancelText="Cancel"
			/>

			{/* Confirmation Modal for Resetting All Players */}
			<ConfirmationModal
				visible={resetAllConfirmationVisible}
				message="Are you sure you want to reset all players?"
				onConfirm={handleConfirmResetAllPlayers}
				onCancel={handleCancelResetAllPlayers}
				confirmText="Reset"
				cancelText="Cancel"
			/>
		</ImageBackground>
	);
}

// Stylesheet for the component
const styles = StyleSheet.create({
	container: {
		width: '100%',
		height: '100%',
		backgroundColor: '#282c34',
		alignItems: 'center',
		paddingTop: 0,
	},
	header: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'flex-start', // Align Back button to the left
		alignItems: 'center',
		height: 80, // Reduced height
		paddingHorizontal: 20, // Reduced padding
		// backgroundColor: '#282c34', // Match background
	},
	menuButton: {
		backgroundColor: '#98CF12',
		paddingVertical: 8,
		paddingHorizontal: 16,
		justifyContent: 'center',
		alignItems: 'center',
		borderWidth: 2.4,
		borderRadius: 10,
	},
	menuButtonText: {
		// fontFamily: 'Nunito_900Black',
		fontSize: 20,
		fontWeight: 'bold',
		letterSpacing: '-.4px',
		textAlign: 'center',
		color: '#000',
	},
	logo: {
		height: '100%', // Adjusted size if needed
		width: 90,
		marginLeft: 'auto', // Push logo to the right if needed
	},
	title: {
		fontSize: 34,
		color: 'white',
		fontWeight: 'bold',
	},
	contentContainer: {
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 'calc(100% - 120px)', // Adjusted to account for reduced header height
		marginBottom: 20,
	},
	playerManagementContainer: {
		width: '100%',
		alignItems: 'center',
	},
	addPlayerButton: {
		backgroundColor: '#4CAF50', // Example color, adjust as needed
		marginTop: 10,
		height: 50,
		borderRadius: 10,
		// width: '80%',
	},
	resetButton: {
		backgroundColor: 'red',
		marginTop: 10,
		height: 50,
		borderRadius: 10,
		// width: '80%',
	},
	// ... (Rest of your styles)
	// Modal styles
	modalOverlay: {
		height: '100%',
		width: '100%',
		backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent background
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalContainer: {
		width: '90%',
		maxHeight: '90%',
		backgroundColor: '#fff',
		borderRadius: 20,
		padding: 20,
		// Shadow for iOS
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	modalScrollContent: {
		paddingBottom: 20,
	},
	modalHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 20,
	},
	modalTitle: {
		fontSize: 20,
		fontWeight: 'bold',
		color: '#333',
	},
	modalLabel: {
		fontSize: 16,
		marginBottom: 5,
		color: '#333',
	},
	nameInputContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 20,
		borderWidth: 1,
		borderColor: '#ccc',
		borderRadius: 10,
		paddingHorizontal: 10,
		paddingVertical: 5,
	},
	avatarImage: {
		width: 40,
		height: 40,
		marginRight: 10,
		borderRadius: 20,
	},
	modalInputWithAvatar: {
		flex: 1,
		height: 40,
		fontSize: 16,
		color: '#333',
	},
	inputWithAvatarPadding: {
		paddingLeft: 0, // Adjust padding if avatar is present
	},
	avatarSelectorContainer: {
		marginBottom: 20,
	},
	modalButtons: {
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		marginTop: 30,
		width: '100%',
	},
	cancelButton: {
		width: '40%',
		backgroundColor: '#ccc',
	},
	saveButton: {
		width: '40%',
		backgroundColor: '#4CAF50',
	},
});
