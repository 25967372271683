// components/GameModeSelection.js
import React, { useContext, useState } from 'react';
import {
	View,
	Text,
	TouchableOpacity,
	StyleSheet,
	ImageBackground,
	Image,
	useWindowDimensions,
} from 'react-native';
import { useNavigate } from 'react-router-dom';
import { GameContext } from '../contexts/GameContext';
import TutorialModal from './TutorialModal'; // Import the TutorialModal
import RulesModal from './RulesModal'; // Import the RulesModal if you have one

function GameModeSelection() {
	const navigate = useNavigate();
	const { setGameMode, scores } = useContext(GameContext); // Destructure scores from GameContext
	const { width, height } = useWindowDimensions();

	const [isTutorialVisible, setTutorialVisible] = useState(false); // Manage modal visibility
	const [isRulesVisible, setRulesVisible] = useState(false); // Manage RulesModal visibility

	// Determine if scores object is empty
	const isScoresEmpty = Object.keys(scores).length === 0;

	// Set button title based on scores
	const newGameButtonTitle = isScoresEmpty ? 'Start New Game' : 'Resume Game';

	// Cornhole board image
	const cornholeBoardImage = require('../assets/images/cornhole_board2.JPEG');

	// Aspect ratio of the cornhole board image (width / height)
	const aspectRatio = 0.5; // Adjust this based on your image's actual aspect ratio

	// Calculate dimensions for the cornhole board image
	const maxImageWidth = width * 0.85; // Maximum width is 85% of screen width
	const maxImageHeight = height * 0.9; // Maximum height is 90% of screen height

	let calculatedWidth = maxImageWidth;
	let calculatedHeight = calculatedWidth / aspectRatio;

	if (calculatedHeight > maxImageHeight) {
		calculatedHeight = maxImageHeight;
		calculatedWidth = calculatedHeight * aspectRatio;
	}

	const beanbagSize = calculatedWidth * 0.35; // Adjust the size of the beanbags relative to the cornhole board

	// Define beanbag buttons with positions as fractions (0 to 1)
	const beanbagButtons = [
		{
			title: newGameButtonTitle, // Dynamic title
			image: require('../assets/beanbag_buttons/red.png'),
			rotation: '-10deg',
			position: { top: 0.2, left: 0.2 },
			onPress: () => {
				setGameMode('cornhole');
				navigate('/players');
			},
		},
		{
			title: 'Learn How to Play!',
			image: require('../assets/beanbag_buttons/green.png'),
			rotation: '12deg',
			position: { top: 0.55, left: 0.12 },
			onPress: () => {
				setTutorialVisible(true);
			},
		},
		{
			title: 'Read the Rules!',
			image: require('../assets/beanbag_buttons/blue.png'),
			rotation: '15deg',
			position: { top: 0.32, left: 0.77 },
			onPress: () => {
				const win = window.open(
					'https://www.playcornholegolf.com/rates-rules',
					'_blank'
				);
				if (win) win.focus();
			},
		},
		{
			title: 'Quick Play',
			image: require('../assets/beanbag_buttons/orange.png'),
			rotation: '-18deg',
			position: { top: 0.83, left: 0.88 },
			onPress: () => {
				setRulesVisible(true); // Open the RulesModal when "Quick Play" is pressed
			},
		},
		// Add 2-3 blank buttons if needed
	];

	return (
		<View style={styles.container}>
			{/* Cornhole Board Image and Beanbag Buttons */}
			<View
				style={{
					position: 'relative',
					width: calculatedWidth,
					height: calculatedHeight,
				}}
			>
				<Image
					source={cornholeBoardImage}
					style={{
						width: calculatedWidth,
						height: calculatedHeight,
					}}
					resizeMode="contain"
				/>

				{/* Beanbag Buttons Overlaid */}
				{beanbagButtons.map((button, index) => (
					<TouchableOpacity
						key={index}
						style={{
							position: 'absolute',
							top: button.position.top * calculatedHeight - beanbagSize / 2,
							left: button.position.left * calculatedWidth - beanbagSize / 2,
							width: beanbagSize,
							height: beanbagSize,
							transform: [{ rotate: button.rotation }],
							alignItems: 'center',
							justifyContent: 'center',
						}}
						onPress={button.onPress}
						activeOpacity={0.8}
						accessibilityLabel={`Button to ${button.title}`}
					>
						<Image
							source={button.image}
							style={{ width: '100%', height: '100%' }}
							resizeMode="cover"
						/>
						{button.title !== '' && (
							<Text style={styles.beanbagText}>{button.title}</Text>
						)}
					</TouchableOpacity>
				))}
			</View>

			{/* Tutorial Modal */}
			<TutorialModal
				visible={isTutorialVisible}
				onClose={() => setTutorialVisible(false)}
				videoSource={require('../assets/video/tutorial.mp4')} // Replace with your video path
			/>

			{/* Rules Modal */}
			<RulesModal
				visible={isRulesVisible}
				onClose={() => setRulesVisible(false)}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#282c34',
		alignItems: 'center',
		justifyContent: 'center',
	},
	logoContainer: {
		height: 175, // Fixed height
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		width: '75%',
		height: '100%',
	},
	beanbagText: {
		position: 'absolute',
		color: '#fff',
		fontWeight: 'bold',
		textAlign: 'center',
		paddingHorizontal: 5,
		width: '80%',
		textShadowColor: 'rgba(0, 0, 0, 0.75)',
		textShadowOffset: { width: -1, height: 1 },
		textShadowRadius: 10,
		fontSize: 14, // Adjust as needed
	},
});

export default GameModeSelection;
