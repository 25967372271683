// // components/UpdatePlayersModal.js
// import React, { useState, useContext } from 'react';
// import {
// 	Modal,
// 	View,
// 	Text,
// 	StyleSheet,
// 	TouchableOpacity,
// 	FlatList,
// 	Image,
// } from 'react-native';
// import { Ionicons } from '@expo/vector-icons';
// import { GameContext } from '../contexts/GameContext';
// import EditPlayerModal from './EditPlayerModal';
// import ConfirmationModal from './ConfirmationModal';
// import { avatars } from './avatars';

// const UpdatePlayersModal = ({ visible, onClose, getDisabledPlayers }) => {
// 	const { players, setPlayers } = useContext(GameContext);
// 	const [editModalVisible, setEditModalVisible] = useState(false);
// 	const [editingPlayerIndex, setEditingPlayerIndex] = useState(null);
// 	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
// 		useState(false);
// 	const [playerToDelete, setPlayerToDelete] = useState(null);

// 	// Determine which avatars are already selected by other players
// 	const getDisabledAvatars = () => {
// 		if (editingPlayerIndex !== null) {
// 			// When editing, exclude the avatar of the player being edited
// 			return players
// 				.filter((_, idx) => idx !== editingPlayerIndex)
// 				.map(player => player.avatar);
// 		}
// 		// When adding, all selected avatars are disabled
// 		return players.map(player => player.avatar);
// 	};

// 	// Function to open the edit modal for a specific player
// 	const handleEditPlayer = index => {
// 		setEditingPlayerIndex(index);
// 		setEditModalVisible(true);
// 	};

// 	// Function to initiate player deletion
// 	const handleDeletePlayer = index => {
// 		setPlayerToDelete(index);
// 		setDeleteConfirmationVisible(true);
// 	};

// 	// Confirm deletion
// 	const confirmDeletePlayer = () => {
// 		if (playerToDelete !== null) {
// 			const updatedPlayers = [...players];
// 			updatedPlayers.splice(playerToDelete, 1);
// 			setPlayers(updatedPlayers);
// 			setDeleteConfirmationVisible(false);
// 			setPlayerToDelete(null);
// 		}
// 	};

// 	// Cancel deletion
// 	const cancelDeletePlayer = () => {
// 		setDeleteConfirmationVisible(false);
// 		setPlayerToDelete(null);
// 	};

// 	// Render each player item
// 	const renderPlayerItem = ({ item, index }) => (
// 		<TouchableOpacity
// 			style={styles.playerItem}
// 			onPress={() => handleEditPlayer(index)}
// 		>
// 			<Image source={avatars[item.avatar].image} style={styles.avatar} />
// 			<Text style={styles.playerName}>{item.name}</Text>
// 			<View style={styles.actionButtons}>
// 				{/* <TouchableOpacity
// 					onPress={() => handleEditPlayer(index)}
// 					style={styles.actionButton}
// 				>
// 					<Ionicons name="pencil" size={24} color="#4CAF50" />
// 				</TouchableOpacity> */}
// 				<TouchableOpacity
// 					onPress={() => handleDeletePlayer(index)}
// 					style={styles.actionButton}
// 				>
// 					<Ionicons name="trash" size={24} color="red" />
// 				</TouchableOpacity>
// 			</View>
// 		</TouchableOpacity>
// 	);

// 	return (
// 		<Modal
// 			transparent={true}
// 			visible={visible}
// 			animationType="slide"
// 			onRequestClose={onClose}
// 		>
// 			<View style={styles.modalOverlay}>
// 				<View style={styles.modalContent}>
// 					{/* Close Button */}
// 					<TouchableOpacity style={styles.closeButton} onPress={onClose}>
// 						<Ionicons name="close" size={30} color="#333" />
// 					</TouchableOpacity>

// 					{/* Modal Title */}
// 					<Text style={styles.title}>Update Players</Text>

// 					{/* Players List */}
// 					{players.length > 0 ? (
// 						<FlatList
// 							data={players}
// 							keyExtractor={(item, index) => index.toString()}
// 							renderItem={renderPlayerItem}
// 							contentContainerStyle={styles.playersList}
// 						/>
// 					) : (
// 						<Text style={styles.noPlayersText}>
// 							No players available. Add players to update.
// 						</Text>
// 					)}
// 				</View>
// 			</View>

// 			{/* Edit Player Modal */}
// 			{editModalVisible && (
// 				<EditPlayerModal
// 					modalVisible={editModalVisible}
// 					handleCancel={() => setEditModalVisible(false)}
// 					handleSavePlayer={() => setEditModalVisible(false)} // Assuming EditPlayerModal handles saving
// 					editingPlayerIndex={editingPlayerIndex}
// 					getDisabledAvatars={getDisabledAvatars}
//                     // playerName={players[editingPlayerIndex].name}
//                     // playerAvatar={players[editingPlayerIndex].avatar}

// 					// Pass other necessary props if needed
// 				/>
// 			)}

// 			{/* Confirmation Modal for Deleting a Player */}
// 			<ConfirmationModal
// 				visible={deleteConfirmationVisible}
// 				message="Are you sure you want to delete this player?"
// 				onConfirm={confirmDeletePlayer}
// 				onCancel={cancelDeletePlayer}
// 				confirmText="Delete"
// 				cancelText="Cancel"
// 			/>
// 		</Modal>
// 	);
// };

// const styles = StyleSheet.create({
// 	modalOverlay: {
// 		flex: 1,
// 		backgroundColor: 'rgba(0,0,0,0.6)',
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 	},
// 	modalContent: {
// 		width: '90%',
// 		maxHeight: '90%',
// 		backgroundColor: '#fff',
// 		borderRadius: 20,
// 		padding: 20,
// 		// Shadow for iOS
// 		shadowColor: '#000',
// 		shadowOffset: { width: 0, height: 2 },
// 		shadowOpacity: 0.25,
// 		shadowRadius: 4,
// 		// Elevation for Android
// 		elevation: 5,
// 	},
// 	closeButton: {
// 		position: 'absolute',
// 		top: 15,
// 		right: 15,
// 		zIndex: 1,
// 		padding: 5,
// 	},
// 	title: {
// 		fontSize: 22,
// 		fontWeight: 'bold',
// 		color: '#333',
// 		marginBottom: 20,
// 		textAlign: 'center',
// 	},
// 	playersList: {
// 		paddingBottom: 20,
// 	},
// 	playerItem: {
// 		flexDirection: 'row',
// 		alignItems: 'center',
// 		marginBottom: 15,
// 		backgroundColor: '#f9f9f9',
// 		padding: 10,
// 		// borderRadius: 10,
// 	},
// 	avatar: {
// 		width: 50,
// 		height: 50,
// 		// borderRadius: 25,
// 		marginRight: 15,
// 	},
// 	playerName: {
// 		flex: 1,
// 		fontSize: 18,
// 		color: '#333',
// 	},
// 	actionButtons: {
// 		flexDirection: 'row',
// 	},
// 	actionButton: {
// 		marginLeft: 10,
// 	},
// 	noPlayersText: {
// 		fontSize: 16,
// 		color: '#777',
// 		textAlign: 'center',
// 		marginTop: 50,
// 	},
// });

// export default UpdatePlayersModal;

// components/UpdatePlayersModal.js
import React, { useState, useContext } from 'react';
import {
	Modal,
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	FlatList,
	Image,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { GameContext } from '../contexts/GameContext';
import EditPlayerModal from './EditPlayerModal';
import ConfirmationModal from './ConfirmationModal';
import { avatars } from './avatars'; // Ensure this imports your avatar images correctly

const UpdatePlayersModal = ({ visible, onClose }) => {
	const { players, setPlayers } = useContext(GameContext);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [editingPlayerIndex, setEditingPlayerIndex] = useState(null);
	const [playerName, setPlayerName] = useState('');
	const [selectedAvatar, setSelectedAvatar] = useState(null);
	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false);
	const [playerToDelete, setPlayerToDelete] = useState(null);

	// Function to open the edit modal for a specific player
	const handleEditPlayer = index => {
		setEditingPlayerIndex(index);
		setPlayerName(players[index].name);
		setSelectedAvatar(players[index].avatar);
		setEditModalVisible(true);
	};

	// Function to initiate player deletion
	const handleDeletePlayer = index => {
		setPlayerToDelete(index);
		setDeleteConfirmationVisible(true);
	};

	// Confirm deletion
	const confirmDeletePlayer = () => {
		if (playerToDelete !== null) {
			const updatedPlayers = [...players];
			updatedPlayers.splice(playerToDelete, 1);
			setPlayers(updatedPlayers);
			setDeleteConfirmationVisible(false);
			setPlayerToDelete(null);
		}
	};

	// Cancel deletion
	const cancelDeletePlayer = () => {
		setDeleteConfirmationVisible(false);
		setPlayerToDelete(null);
	};

	// Determine which avatars are already selected by other players
	const getDisabledAvatars = () => {
		if (editingPlayerIndex !== null) {
			// When editing, exclude the avatar of the player being edited
			return players
				.filter((_, idx) => idx !== editingPlayerIndex)
				.map(player => player.avatar);
		}
		// When adding, all selected avatars are disabled
		return players.map(player => player.avatar);
	};

	// Function to handle avatar selection
	const handleAvatarSelect = avatarId => {
		setSelectedAvatar(avatarId);
	};

	// Function to save the edited player
	const handleSavePlayer = () => {
		if (playerName.trim() === '') {
			alert('Invalid Name. Please enter a valid name.');
			return;
		}
		if (selectedAvatar === null) {
			alert('No Avatar Selected. Please select an avatar.');
			return;
		}

		// Check if the selected avatar is already taken by another player
		const isAvatarTaken = players.some(
			(player, idx) =>
				player.avatar === selectedAvatar && idx !== editingPlayerIndex
		);
		if (isAvatarTaken) {
			alert(
				'Avatar Unavailable. This avatar is already taken. Please select another one.'
			);
			return;
		}

		// Update the player in the list
		const updatedPlayers = [...players];
		updatedPlayers[editingPlayerIndex] = {
			name: playerName.trim(),
			avatar: selectedAvatar,
		};
		setPlayers(updatedPlayers);
		setEditModalVisible(false);
		setEditingPlayerIndex(null);
		setPlayerName('');
		setSelectedAvatar(null);
	};

	// Function to cancel editing
	const handleCancelEdit = () => {
		setEditModalVisible(false);
		setEditingPlayerIndex(null);
		setPlayerName('');
		setSelectedAvatar(null);
	};

	// Render each player item
	const renderPlayerItem = ({ item, index }) => (
		<TouchableOpacity
			style={styles.playerItem}
			onPress={() => handleEditPlayer(index)}
		>
			<Image source={avatars[item.avatar].image} style={styles.avatar} />
			<Text style={styles.playerName}>{item.name}</Text>
			<View style={styles.actionButtons}>
				{/* <TouchableOpacity
					onPress={() => handleEditPlayer(index)}
					style={styles.actionButton}
				>
					<Ionicons name="pencil" size={24} color="#4CAF50" />
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => handleDeletePlayer(index)}
					style={styles.actionButton}
				>
					<Ionicons name="trash" size={24} color="red" />
				</TouchableOpacity> */}
			</View>
		</TouchableOpacity>
	);

	return (
		<Modal
			transparent={true}
			visible={visible}
			animationType="slide"
			onRequestClose={onClose}
		>
			<View style={styles.modalOverlay}>
				<View style={styles.modalContent}>
					{/* Close Button */}
					<TouchableOpacity style={styles.closeButton} onPress={onClose}>
						<Ionicons name="close" size={30} color="#333" />
					</TouchableOpacity>

					{/* Modal Title */}
					<Text style={styles.title}>Update Players</Text>

					{/* Players List */}
					{players.length > 0 ? (
						<FlatList
							data={players}
							keyExtractor={(item, index) => index.toString()}
							renderItem={renderPlayerItem}
							contentContainerStyle={styles.playersList}
						/>
					) : (
						<Text style={styles.noPlayersText}>
							No players available. Add players to update.
						</Text>
					)}
				</View>
			</View>

			{/* Edit Player Modal */}
			{editModalVisible && (
				<EditPlayerModal
					modalVisible={editModalVisible}
					handleCancel={handleCancelEdit}
					handleSavePlayer={handleSavePlayer}
					handleDeletePlayer={() => {}}
					editingPlayerIndex={editingPlayerIndex}
					playerName={playerName}
					setPlayerName={setPlayerName}
					selectedAvatar={selectedAvatar}
					handleAvatarSelect={handleAvatarSelect}
					getDisabledAvatars={getDisabledAvatars}
				/>
			)}

			{/* Confirmation Modal for Deleting a Player */}
			<ConfirmationModal
				visible={deleteConfirmationVisible}
				message="Are you sure you want to delete this player?"
				onConfirm={confirmDeletePlayer}
				onCancel={cancelDeletePlayer}
				confirmText="Delete"
				cancelText="Cancel"
			/>
		</Modal>
	);
};

const styles = StyleSheet.create({
	modalOverlay: {
		flex: 1,
		backgroundColor: 'rgba(0,0,0,0.6)',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalContent: {
		width: '90%',
		maxHeight: '90%',
		backgroundColor: '#fff',
		borderRadius: 20,
		padding: 20,
		// Shadow for iOS
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 4,
		// Elevation for Android
		elevation: 5,
	},
	closeButton: {
		position: 'absolute',
		top: 15,
		right: 15,
		zIndex: 1,
		padding: 5,
	},
	title: {
		fontSize: 22,
		fontWeight: 'bold',
		color: '#333',
		marginBottom: 20,
		textAlign: 'center',
	},
	playersList: {
		paddingBottom: 20,
	},
	playerItem: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 15,
		backgroundColor: '#c0c0c0',
		padding: 10,
		borderRadius: 5,
	},
	avatar: {
		width: 50,
		height: 50,
		marginRight: 15,
	},
	playerName: {
		flex: 1,
		fontSize: 18,
		color: '#333',
	},
	actionButtons: {
		flexDirection: 'row',
	},
	actionButton: {
		marginLeft: 10,
	},
	noPlayersText: {
		fontSize: 16,
		color: '#777',
		textAlign: 'center',
		marginTop: 50,
	},
});

export default UpdatePlayersModal;
