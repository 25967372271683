// // components/MenuModal.js
// import React, { useContext, useState } from 'react';
// import {
// 	View,
// 	Text,
// 	TouchableOpacity,
// 	StyleSheet,
// 	Modal,
// 	SafeAreaView,
// 	Image,
// 	useWindowDimensions,
// } from 'react-native';
// import { useNavigate } from 'react-router-dom';
// import { GameContext } from '../contexts/GameContext';
// import { Ionicons } from '@expo/vector-icons';
// import ConfirmationModal from './ConfirmationModal';
// import TutorialModal from './TutorialModal';
// import RulesModal from './RulesModal'; // Import the RulesModal component

// const MenuModal = ({ visible, onClose, onResetGame }) => {
// 	const { setPlayers, setScores, setCurrentHole } = useContext(GameContext);
// 	const navigate = useNavigate();
// 	const { width, height } = useWindowDimensions();
// 	const [isTutorialVisible, setTutorialVisible] = useState(false);
// 	const [confirmVisible, setConfirmVisible] = useState(false);
// 	const [isRulesVisible, setRulesVisible] = useState(false); // State for the RulesModal

// 	// Cornhole board image
// 	const cornholeBoardImage = require('../assets/images/cornhole_board2.JPEG');

// 	// Aspect ratio of the cornhole board image (width / height)
// 	const aspectRatio = 0.5; // Adjust this based on your image's actual aspect ratio

// 	// Calculate dimensions for the cornhole board image
// 	const maxImageWidth = width * 0.85; // Maximum width is 85% of screen width
// 	const maxImageHeight = height * 0.9; // Maximum height is 90% of screen height

// 	let calculatedWidth = maxImageWidth;
// 	let calculatedHeight = calculatedWidth / aspectRatio;

// 	if (calculatedHeight > maxImageHeight) {
// 		calculatedHeight = maxImageHeight;
// 		calculatedWidth = calculatedHeight * aspectRatio;
// 	}

// 	const beanbagSize = calculatedWidth * 0.35; // Adjust the size of the beanbags relative to the cornhole board

// 	// Define beanbag buttons with positions as fractions (0 to 1)
// 	const beanbagButtons = [
// 		{
// 			title: 'Update Players',
// 			image: require('../assets/beanbag_buttons/pink.PNG'),
// 			rotation: '-10deg',
// 			position: { top: 0.5, left: 0.5 }, // 12% from top, 20% from left
// 			onPress: () => {
// 				onClose();
// 				navigate('/players');
// 			},
// 		},
// 		{
// 			title: 'Rules',
// 			image: require('../assets/beanbag_buttons/blue.png'),
// 			rotation: '15deg',
// 			position: { top: 0.32, left: 0.77 },
// 			onPress: () => {
// 				const win = window.open(
// 					'https://www.playcornholegolf.com/rates-rules',
// 					'_blank'
// 				);
// 				if (win) win.focus();
// 			},
// 		},
// 		{
// 			title: 'How to Use the App',
// 			image: require('../assets/beanbag_buttons/green.png'),
// 			rotation: '12deg',
// 			position: { top: 0.55, left: 0.12 },
// 			onPress: () => {
// 				setTutorialVisible(true);
// 			},
// 		},
// 		{
// 			title: 'Reset Game',
// 			image: require('../assets/beanbag_buttons/red.png'),
// 			rotation: '-10deg',
// 			position: { top: 0.2, left: 0.2 },
// 			onPress: () => {
// 				setConfirmVisible(true);
// 			},
// 		},
// 		{
// 			title: 'Quick Play',
// 			image: require('../assets/beanbag_buttons/orange.png'),
// 			rotation: '-18deg',
// 			position: { top: 0.83, left: 0.88 },
// 			onPress: () => {
// 				setRulesVisible(true); // Open the RulesModal when "Quick Play" is pressed
// 			},
// 		},
// 	];

// 	// Handle Reset Game Confirmation
// 	const handleConfirmReset = () => {
// 		setConfirmVisible(false);
// 		onResetGame();
// 	};

// 	const handleCancelReset = () => {
// 		setConfirmVisible(false);
// 	};

// 	return (
// 		<Modal
// 			transparent={true}
// 			visible={visible}
// 			animationType="slide"
// 			onRequestClose={onClose}
// 		>
// 			<SafeAreaView style={styles.menuModalOverlay}>
// 				<View style={styles.modalContainer}>
// 					<View style={styles.menuModalContent}>
// 						{/* Close Button at the Top Right */}
// 						<TouchableOpacity style={styles.closeButton} onPress={onClose}>
// 							<Ionicons name="close" size={30} color="white" />
// 						</TouchableOpacity>

// 						{/* Cornhole Board Image and Beanbag Buttons */}
// 						<View
// 							style={{
// 								position: 'relative',
// 								width: calculatedWidth,
// 								height: calculatedHeight,
// 							}}
// 						>
// 							<Image
// 								source={cornholeBoardImage}
// 								style={{
// 									width: calculatedWidth,
// 									height: calculatedHeight,
// 								}}
// 								resizeMode="contain"
// 							/>

// 							{/* Beanbag Buttons Overlaid */}
// 							{beanbagButtons.map((button, index) => (
// 								<TouchableOpacity
// 									key={index}
// 									style={{
// 										position: 'absolute',
// 										top: button.position.top * calculatedHeight - beanbagSize / 2,
// 										left: button.position.left * calculatedWidth - beanbagSize / 2,
// 										width: beanbagSize,
// 										height: beanbagSize,
// 										transform: [{ rotate: button.rotation }],
// 										alignItems: 'center',
// 										justifyContent: 'center',
// 									}}
// 									onPress={button.onPress}
// 									activeOpacity={0.8}
// 								>
// 									<Image
// 										source={button.image}
// 										style={{ width: '100%', height: '100%' }}
// 										resizeMode="cover"
// 									/>
// 									{button.title !== '' && (
// 										<Text style={styles.beanbagText}>{button.title}</Text>
// 									)}
// 								</TouchableOpacity>
// 							))}
// 						</View>
// 					</View>
// 				</View>
// 			</SafeAreaView>

// 			{/* Tutorial Modal */}
// 			<TutorialModal
// 				visible={isTutorialVisible}
// 				onClose={() => setTutorialVisible(false)}
// 				videoSource={require('../assets/video/tutorial.mp4')} // Replace with your video path
// 			/>

// 			{/* Confirmation Modal for Reset Game */}
// 			<ConfirmationModal
// 				visible={confirmVisible}
// 				message="Are you sure you want to reset the game?"
// 				onConfirm={handleConfirmReset}
// 				onCancel={handleCancelReset}
// 				confirmText="Reset"
// 				cancelText="Cancel"
// 			/>

// 			{/* Rules Modal for Quick Play */}
// 			<RulesModal
// 				visible={isRulesVisible}
// 				onClose={() => setRulesVisible(false)}
// 			/>
// 		</Modal>
// 	);
// };

// const styles = StyleSheet.create({
// 	menuModalOverlay: {
// 		flex: 1,
// 		backgroundColor: 'rgba(0,0,0,0.8)', // Darker overlay for prominence
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 		height: '100%',
// 		width: '100%',
// 	},
// 	modalContainer: {
// 		height: '95%',
// 		width: '95%',
// 	},
// 	menuModalContent: {
// 		height: '100%',
// 		width: '100%',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		backgroundColor: '#282c34', // Match your app's background
// 		paddingVertical: 20,
// 	},
// 	closeButton: {
// 		position: 'absolute',
// 		top: 20,
// 		right: 20,
// 		zIndex: 1,
// 		padding: 10,
// 	},
// 	beanbagText: {
// 		position: 'absolute',
// 		color: '#fff',
// 		fontWeight: 'bold',
// 		textAlign: 'center',
// 		paddingHorizontal: 5,
// 		width: '80%',
// 		textShadowColor: 'rgba(0, 0, 0, 0.75)',
// 		textShadowOffset: { width: -1, height: 1 },
// 		textShadowRadius: 10,
// 		fontSize: 14, // Adjust as needed
// 	},
// });

// export default MenuModal;

// components/MenuModal.js
import React, { useContext, useState } from 'react';
import {
	View,
	Text,
	TouchableOpacity,
	StyleSheet,
	Modal,
	SafeAreaView,
	Image,
	useWindowDimensions,
} from 'react-native';
import { useNavigate } from 'react-router-dom';
import { GameContext } from '../contexts/GameContext';
import { Ionicons } from '@expo/vector-icons';
import ConfirmationModal from './ConfirmationModal';
import TutorialModal from './TutorialModal';
import RulesModal from './RulesModal'; // Import the RulesModal component
import UpdatePlayersModal from './UpdatePlayersModal'; // Import the new UpdatePlayersModal component

const MenuModal = ({ visible, onClose, onResetGame }) => {
	const { setPlayers, setScores, setCurrentHole } = useContext(GameContext);
	const navigate = useNavigate();
	const { width, height } = useWindowDimensions();
	const [isTutorialVisible, setTutorialVisible] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [isRulesVisible, setRulesVisible] = useState(false); // State for the RulesModal
	const [isUpdatePlayersVisible, setUpdatePlayersVisible] = useState(false); // State for UpdatePlayersModal

	// Cornhole board image
	const cornholeBoardImage = require('../assets/images/cornhole_board2.JPEG');

	// Aspect ratio of the cornhole board image (width / height)
	const aspectRatio = 0.5; // Adjust this based on your image's actual aspect ratio

	// Calculate dimensions for the cornhole board image
	const maxImageWidth = width * 0.85; // Maximum width is 85% of screen width
	const maxImageHeight = height * 0.9; // Maximum height is 90% of screen height

	let calculatedWidth = maxImageWidth;
	let calculatedHeight = calculatedWidth / aspectRatio;

	if (calculatedHeight > maxImageHeight) {
		calculatedHeight = maxImageHeight;
		calculatedWidth = calculatedHeight * aspectRatio;
	}

	const beanbagSize = calculatedWidth * 0.35; // Adjust the size of the beanbags relative to the cornhole board

	// Define beanbag buttons with positions as fractions (0 to 1)
	const beanbagButtons = [
		{
			title: 'Update Players',
			image: require('../assets/beanbag_buttons/pink.PNG'),
			rotation: '-10deg',
			position: { top: 0.5, left: 0.5 }, // Adjust positions as needed
			onPress: () => {
				setUpdatePlayersVisible(true); // Open the UpdatePlayersModal
			},
		},
		{
			title: 'Rules',
			image: require('../assets/beanbag_buttons/blue.png'),
			rotation: '15deg',
			position: { top: 0.32, left: 0.77 },
			onPress: () => {
				const win = window.open(
					'https://www.playcornholegolf.com/rates-rules',
					'_blank'
				);
				if (win) win.focus();
			},
		},
		{
			title: 'How to Use the App',
			image: require('../assets/beanbag_buttons/green.png'),
			rotation: '12deg',
			position: { top: 0.55, left: 0.12 },
			onPress: () => {
				setTutorialVisible(true);
			},
		},
		{
			title: 'Reset Game',
			image: require('../assets/beanbag_buttons/red.png'),
			rotation: '-10deg',
			position: { top: 0.2, left: 0.2 },
			onPress: () => {
				setConfirmVisible(true);
			},
		},
		{
			title: 'Quick Play',
			image: require('../assets/beanbag_buttons/orange.png'),
			rotation: '-18deg',
			position: { top: 0.83, left: 0.88 },
			onPress: () => {
				setRulesVisible(true); // Open the RulesModal when "Quick Play" is pressed
			},
		},
	];

	// Handle Reset Game Confirmation
	const handleConfirmReset = () => {
		setConfirmVisible(false);
		onResetGame();
	};

	const handleCancelReset = () => {
		setConfirmVisible(false);
	};

	return (
		<Modal
			transparent={true}
			visible={visible}
			animationType="slide"
			onRequestClose={onClose}
		>
			<SafeAreaView style={styles.menuModalOverlay}>
				<View style={styles.modalContainer}>
					<View style={styles.menuModalContent}>
						{/* Close Button at the Top Right */}
						<TouchableOpacity style={styles.closeButton} onPress={onClose}>
							<Ionicons name="close" size={30} color="white" />
						</TouchableOpacity>

						{/* Cornhole Board Image and Beanbag Buttons */}
						<View
							style={{
								position: 'relative',
								width: calculatedWidth,
								height: calculatedHeight,
							}}
						>
							<Image
								source={cornholeBoardImage}
								style={{
									width: calculatedWidth,
									height: calculatedHeight,
								}}
								resizeMode="contain"
							/>

							{/* Beanbag Buttons Overlaid */}
							{beanbagButtons.map((button, index) => (
								<TouchableOpacity
									key={index}
									style={{
										position: 'absolute',
										top: button.position.top * calculatedHeight - beanbagSize / 2,
										left: button.position.left * calculatedWidth - beanbagSize / 2,
										width: beanbagSize,
										height: beanbagSize,
										transform: [{ rotate: button.rotation }],
										alignItems: 'center',
										justifyContent: 'center',
									}}
									onPress={button.onPress}
									activeOpacity={0.8}
								>
									<Image
										source={button.image}
										style={{ width: '100%', height: '100%' }}
										resizeMode="cover"
									/>
									{button.title !== '' && (
										<Text style={styles.beanbagText}>{button.title}</Text>
									)}
								</TouchableOpacity>
							))}
						</View>
					</View>
				</View>
			</SafeAreaView>

			{/* Tutorial Modal */}
			<TutorialModal
				visible={isTutorialVisible}
				onClose={() => setTutorialVisible(false)}
				videoSource={require('../assets/video/tutorial.mp4')} // Replace with your video path
			/>

			{/* Confirmation Modal for Reset Game */}
			<ConfirmationModal
				visible={confirmVisible}
				message="Are you sure you want to reset the game?"
				onConfirm={handleConfirmReset}
				onCancel={handleCancelReset}
				confirmText="Reset"
				cancelText="Cancel"
			/>

			{/* Rules Modal for Quick Play */}
			<RulesModal
				visible={isRulesVisible}
				onClose={() => setRulesVisible(false)}
			/>

			{/* Update Players Modal */}
			<UpdatePlayersModal
				visible={isUpdatePlayersVisible}
				onClose={() => setUpdatePlayersVisible(false)}
			/>
		</Modal>
	);
};

const styles = StyleSheet.create({
	menuModalOverlay: {
		flex: 1,
		backgroundColor: 'rgba(0,0,0,0.8)', // Darker overlay for prominence
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
	},
	modalContainer: {
		height: '95%',
		width: '95%',
	},
	menuModalContent: {
		height: '100%',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#282c34', // Match your app's background
		paddingVertical: 20,
	},
	closeButton: {
		position: 'absolute',
		top: 20,
		right: 20,
		zIndex: 1,
		padding: 10,
	},
	beanbagText: {
		position: 'absolute',
		color: '#fff',
		fontWeight: 'bold',
		textAlign: 'center',
		paddingHorizontal: 5,
		width: '80%',
		textShadowColor: 'rgba(0, 0, 0, 0.75)',
		textShadowOffset: { width: -1, height: 1 },
		textShadowRadius: 10,
		fontSize: 14, // Adjust as needed
	},
});

export default MenuModal;
