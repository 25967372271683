// components/avatars.js
export const avatars = [
	// Normal Bean Bags

	{ id: 0, type: 'normal', image: require('../assets/avatars/maroon_bag.png') },
	{ id: 1, type: 'normal', image: require('../assets/avatars/yellow_bag.png') },
	{ id: 2, type: 'normal', image: require('../assets/avatars/purple_bag.png') },
	{ id: 3, type: 'normal', image: require('../assets/avatars/orange_bag.png') },
	{
		id: 4,
		type: 'normal',
		image: require('../assets/avatars/dark_blue_bag.png'),
	},
	{
		id: 5,
		type: 'normal',
		image: require('../assets/avatars/striped_bag.png'),
	},
	{ id: 6, type: 'normal', image: require('../assets/avatars/green_bag.png') },
	{ id: 7, type: 'normal', image: require('../assets/avatars/blue_bag.png') },
	{ id: 8, type: 'normal', image: require('../assets/avatars/stars_bag.png') },
	{ id: 9, type: 'normal', image: require('../assets/avatars/black_bag.png') },
	{ id: 10, type: 'normal', image: require('../assets/avatars/gray_bag.png') },
	{ id: 11, type: 'normal', image: require('../assets/avatars/white_bag.png') },
	{ id: 12, type: 'normal', image: require('../assets/avatars/red_bag.png') },

	// Kids Bean Bags
	{
		id: 13,
		type: 'kids',
		image: require('../assets/avatars/kids_blue_bag.png'),
	},
	{
		id: 14,
		type: 'kids',
		image: require('../assets/avatars/kids_dark_blue_bag.png'),
	},
	{
		id: 15,
		type: 'kids',
		image: require('../assets/avatars/kids_green_bag.png'),
	},
	{
		id: 16,
		type: 'kids',
		image: require('../assets/avatars/kids_maroon_bag.png'),
	},
	{
		id: 17,
		type: 'kids',
		image: require('../assets/avatars/kids_orange_bag.png'),
	},
	{
		id: 18,
		type: 'kids',
		image: require('../assets/avatars/kids_pink_bag.png'),
	},
	{
		id: 19,
		type: 'kids',
		image: require('../assets/avatars/kids_purple_bag.png'),
	},
	{
		id: 20,
		type: 'kids',
		image: require('../assets/avatars/kids_red_bag.png'),
	},
	{
		id: 21,
		type: 'kids',
		image: require('../assets/avatars/kids_yellow_bag.png'),
	},
	// Add more avatars as needed
];
