// components/AvatarSelector.js
import React, { useState } from 'react';
import {
	View,
	TouchableOpacity,
	Text,
	StyleSheet,
	ScrollView,
	Image,
} from 'react-native';
import { avatars } from './avatars'; // Import the centralized avatars
import { Nunito_900Black, useFonts } from '@expo-google-fonts/nunito';

export default function AvatarSelector({
	selectedAvatar,
	onSelectAvatar,
	disabledAvatars,
}) {
	const [fontsLoaded] = useFonts({
		Nunito_900Black,
	});

	// Separate avatars into normal and kids based on type
	const normalBags = avatars.filter(avatar => avatar.type === 'normal');
	const kidsBags = avatars.filter(avatar => avatar.type === 'kids');

	if (!fontsLoaded) {
		return null; // Or a loading spinner
	}

	return (
		<ScrollView contentContainerStyle={styles.scrollContainer}>
			{/* Normal Bean Bags Section */}
			<View style={styles.section}>
				{/* <View> */}
				<Text style={styles.sectionTitle}>Regulation Bean Bags</Text>
				{/* </View> */}
				<View style={styles.avatarContainer}>
					{normalBags.map(avatar => {
						const isDisabled =
							disabledAvatars?.includes(avatar.id) && avatar.id !== selectedAvatar;
						return (
							<TouchableOpacity
								key={avatar.id}
								onPress={() => !isDisabled && onSelectAvatar(avatar.id)}
								style={styles.avatarTouchable}
								activeOpacity={isDisabled ? 1 : 0.7} // Disable opacity change if disabled
								disabled={isDisabled} // Disable touch if avatar is already selected
							>
								<View
									style={[
										styles.avatar,
										selectedAvatar === avatar.id && styles.selectedAvatar,
										isDisabled && styles.disabledAvatar,
									]}
								>
									<Image style={{ height: 50, width: 50 }} source={avatar.image} />
								</View>
							</TouchableOpacity>
						);
					})}
				</View>
			</View>

			{/* Kids Bean Bags Section */}
			<View style={styles.section}>
				<Text style={styles.sectionTitle}>Kids Bean Bags</Text>
				<View style={styles.avatarContainer}>
					{kidsBags.map(avatar => {
						const isDisabled =
							disabledAvatars?.includes(avatar.id) && avatar.id !== selectedAvatar;
						return (
							<TouchableOpacity
								key={avatar.id}
								onPress={() => !isDisabled && onSelectAvatar(avatar.id)}
								style={styles.avatarTouchable}
								activeOpacity={isDisabled ? 1 : 0.7}
								disabled={isDisabled}
							>
								<View
									style={[
										styles.avatar,
										selectedAvatar === avatar.id && styles.selectedAvatar,
										isDisabled && styles.disabledAvatar,
									]}
								>
									<Image style={{ height: 50, width: 50 }} source={avatar.image} />
								</View>
							</TouchableOpacity>
						);
					})}
				</View>
			</View>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	scrollContainer: {
		paddingVertical: 10,
	},
	section: {
		marginBottom: 20,
		paddingHorizontal: 10,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: 'bold',
		marginBottom: 10,
		fontFamily: 'Nunito_900Black', // Uncomment if the font is available
		color: '#333',
		textAlign: 'center',
	},
	avatarContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	avatarTouchable: {
		margin: 5,
	},
	avatar: {
		borderWidth: 2,
		width: 60,
		height: 60,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		borderColor: 'transparent',
	},
	selectedAvatar: {
		borderColor: 'black',
		borderWidth: 2,
	},
	disabledAvatar: {
		opacity: 0.3, // Gray out the avatar
	},
});
