// // components/RulesModal.js
// import React from 'react';
// import {
// 	Modal,
// 	View,
// 	Text,
// 	StyleSheet,
// 	TouchableOpacity,
// 	ScrollView,
// 	ImageBackground,
// } from 'react-native';
// import { Ionicons } from '@expo/vector-icons';

// const RulesModal = ({ visible, onClose }) => {
// 	return (
// 		<Modal
// 			transparent={true}
// 			visible={visible}
// 			animationType="slide"
// 			onRequestClose={onClose}
// 		>
// 			<View style={styles.modalOverlay}>
// 				<View style={styles.modalContent}>
// 					<ImageBackground
// 						style={styles.container}
// 						source={require('../assets/images/textured_background2.png')}
// 						resizeMode="cover"
// 					>
// 						{/* Close Button */}
// 						<TouchableOpacity style={styles.closeButton} onPress={onClose}>
// 							<Ionicons name="close" size={30} color="white" />
// 						</TouchableOpacity>

// 						{/* Content */}
// 						<ScrollView contentContainerStyle={styles.scrollContent}>
// 							<Text style={styles.title}>Quick Play Rules</Text>
// 							<Text style={styles.text}>
// 								{/* Add your rules here. This is placeholder text that you can replace with the actual rules. */}
// 								1. All Players take turns from Drive Box
// 								{'\n\n'}
// 								2. Record IN, ON, or OFF for each Player
// 								{'\n\n'}
// 								3. Repeat for other 2 Boxes
// 								{'\n\n'}
// 								4. Move on to next Hole
// 							</Text>
// 							<Text style={styles.title2}>Button Key</Text>
// 							<Text style={styles.text}>
// 								{/* Add your rules here. This is placeholder text that you can replace with the actual rules. */}
// 								IN = Bean Bag in Hole
// 								{'\n\n'}ON = Bean Bag on Board
// 								{'\n\n'}OFF = Bean Bag off Board
// 							</Text>
// 						</ScrollView>
// 					</ImageBackground>
// 				</View>
// 			</View>
// 		</Modal>
// 	);
// };

// const styles = StyleSheet.create({
// 	container: {
// 		height: '100%',
// 		width: '100%',
// 		backgroundColor: '#282c34',
// 		alignItems: 'center',
// 	},
// 	modalOverlay: {
// 		flex: 1,
// 		height: '100%',
// 		width: '100%',
// 		backgroundColor: 'rgba(0,0,0,0.8)',
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 	},
// 	modalContent: {
// 		width: '85%',
// 		height: '80%',
// 		// backgroundColor: '#FFA500', // Orange background
// 		borderRadius: 10,
// 		padding: 20,
// 		alignItems: 'center',
// 	},
// 	closeButton: {
// 		position: 'absolute',
// 		top: 10,
// 		right: 10,
// 		zIndex: 1,
// 		padding: 5,
// 	},
// 	scrollContent: {
// 		paddingTop: 40,
// 		width: '80%',
// 		alignItems: 'flex-start',
// 	},
// 	title: {
// 		fontSize: 24,
// 		fontWeight: 'bold',
// 		color: '#fff',
// 		marginBottom: 20,
// 		display: 'flex',
// 		justifyContent: 'center',
// 		width: '100%',
// 	},
// 	title2: {
// 		marginTop: 20,
// 		fontSize: 24,
// 		fontWeight: 'bold',
// 		color: '#fff',
// 		marginBottom: 20,
// 		display: 'flex',
// 		justifyContent: 'center',
// 		width: '100%',
// 	},
// 	text: {
// 		fontSize: 16,
// 		fontWeight: '600',
// 		color: '#fff',
// 		textAlign: 'left',
// 	},
// });

// export default RulesModal;

// components/RulesModal.js
import React, { useState, useEffect } from 'react';
import {
	Modal,
	View,
	StyleSheet,
	TouchableOpacity,
	Image,
	Dimensions,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import image from '../assets/images/quick_play.jpeg';

const RulesModal = ({ visible, onClose }) => {
	const [imageDimensions, setImageDimensions] = useState({
		width: 0,
		height: 0,
	});
	const screenWidth = Dimensions.get('window').width;
	const screenHeight = Dimensions.get('window').height;
	const maxWidth = screenWidth * 0.9;
	const maxHeight = screenHeight * 0.9;

	useEffect(() => {
		if (image) {
			Image.getSize(
				typeof image === 'number' ? Image.resolveAssetSource(image).uri : image,
				(width, height) => {
					const aspectRatio = width / height;
					let finalWidth = maxWidth;
					let finalHeight = maxWidth / aspectRatio;

					if (finalHeight > maxHeight) {
						finalHeight = maxHeight;
						finalWidth = maxHeight * aspectRatio;
					}

					setImageDimensions({ width: finalWidth, height: finalHeight });
				},
				error => {
					console.error('Failed to get image dimensions:', error);
				}
			);
		}
	}, [image, maxWidth, maxHeight]);

	return (
		<Modal
			transparent={true}
			visible={visible}
			animationType="fade"
			onRequestClose={onClose}
		>
			<View style={styles.modalOverlay}>
				<View style={styles.modalContent}>
					{/* Close Button */}
					<TouchableOpacity style={styles.closeButton} onPress={onClose}>
						<Ionicons name="close" size={30} color="#333" />
					</TouchableOpacity>

					{/* Image */}
					{imageDimensions.width > 0 && (
						<Image
							source={image}
							style={[
								styles.image,
								{
									width: imageDimensions.width,
									height: imageDimensions.height,
								},
							]}
							resizeMode="contain"
						/>
					)}
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	modalOverlay: {
		flex: 1,
		backgroundColor: 'rgba(0,0,0,0.6)',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalContent: {
		// The container will size itself based on the image dimensions
		backgroundColor: '#fff',
		borderRadius: 10,
		padding: 10,
		alignItems: 'center',
		// Optional: add shadow for iOS
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 4,
		// Optional: add elevation for Android
		elevation: 5,
	},
	closeButton: {
		position: 'absolute',
		top: 5,
		right: 5,
		zIndex: 1,
		padding: 5,
	},
	image: {
		// The width and height are set dynamically
		borderRadius: 10,
	},
});

export default RulesModal;
