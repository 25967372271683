// components/SplashScreen.js
import React, { useEffect, useRef } from 'react';
import {
	View,
	Image,
	StyleSheet,
	Animated,
	ImageBackground,
} from 'react-native';

export default function SplashScreen({ onFinish }) {
	const fadeAnim = useRef(new Animated.Value(0)).current;
	const scaleAnim = useRef(new Animated.Value(0.8)).current;

	useEffect(() => {
		Animated.sequence([
			Animated.parallel([
				Animated.timing(fadeAnim, {
					toValue: 1,
					duration: 500,
					useNativeDriver: true,
				}),
				Animated.spring(scaleAnim, {
					toValue: 1,
					friction: 5,
					useNativeDriver: true,
				}),
			]),
			Animated.delay(500),
		]).start(() => {
			onFinish();
		});
	}, [fadeAnim, scaleAnim, onFinish]);

	return (
		<ImageBackground
			source={require('../assets/images/textured_background2.png')}
			style={styles.container}
			resizeMode="cover"
		>
			<Animated.Image
				source={require('../assets/images/logo.webp')} // Replace with your logo path
				style={[
					styles.logo,
					{
						opacity: fadeAnim,
						transform: [{ scale: scaleAnim }],
					},
				]}
				resizeMode="contain"
			/>
		</ImageBackground>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#282c34',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		width: 200,
		height: 200,
	},
});
